import React, { useEffect, useState } from "react";
import { ADMIN_FORM_LIST_URL, BackyCheckFormName, BackycheckFormType, FieldTypes } from "../../store/constants/constants";
import { Box, Button, CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import { getBackycheckDetails, getBackycheckPolicyForm, updateBackycheckPolicyForm } from "../../api/backycheck/backycheck.class";
import "../../assets/sinner_css/DocSign.css";
import "../../assets/sinner_css/assignFields.css";
import { useNavigate, useParams } from "react-router-dom";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { downloadFile, uploadFormPDF } from "../../utils/s3Config";
import AdminPDFView from "./AdminPDFView";
import Alerts from "../../components/common/Alert";
import { ShowAlert } from "../../store/actions/alertActions";
import { useDispatch } from "react-redux";


function AdminDocSign({ formType, is_admin = false }) {
  const { id, is_admin_user } = useParams();  
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState('');
  const [formInputs, setFormInputs] = useState([]);
  const [backyCheckDetails, setBackyCheckDetails] = useState({});
  const [preFillData, setPreFillData] = useState({ amici: [], npcs: []});
  const [isFormFilled, setIsFormFilled] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getBackycheckPolicyFormApi = async () => {
      try {
        const backycheckData = await getBackycheckDetails(id).then(function (res) {
          return res.data.results;
        });
        if(backycheckData){
          if(formType === BackycheckFormType.AMICI && backycheckData.is_amici_fill !== 0){
            const amiciData = JSON.parse(backycheckData.amici_data);
            setPreFillData((prevData) => ({
              ...prevData,
              amici: amiciData,
            }));
          }
          if(formType === BackycheckFormType.NPCS && backycheckData.is_npcs_fill !== 0){
            const npcsData = JSON.parse(backycheckData.npcs_data);
            setPreFillData((prevData) => ({
              ...prevData,
              npcs: npcsData,
            }));
          }
          setBackyCheckDetails(backycheckData);
          setIsFormFilled(formType === BackycheckFormType.NPCS && backycheckData.is_npcs_fill === 2);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (!is_admin) {
      navigate('/');
    }
    if (formType && is_admin) {
      getBackycheckPolicyFormApi();
    }
  }, [formType, is_admin]);

  const getFormName = () => {
    if (formType === 1) {
      return BackyCheckFormName.AMICI_FORM_NAME;
    } else if (formType === 2) {
      return BackyCheckFormName.NPCS_FORM_NAME;
    }
  }

  const validateFields = () => {
    const error = [];
    setFormInputs(prevFormInputs =>
      prevFormInputs.map(signer => {
        if (!signer.isRecruiter) {
          // Create a new fields array with updated error messages
          const updatedFields = signer.fields.map(field => {
            if (field.isRequired) {
              if (!field.isFilled) {
                error.push(field);
                return {
                  ...field,
                  errorMessage: "Highlighted fields are required",
                };
              } else {
                return {
                  ...field,
                  errorMessage: null,
                };
              }
            }
            return field; // Return unmodified field
          });

          // Return updated signer object
          return {
            ...signer,
            fields: updatedFields,
          };
        }
        return signer; // Return unmodified signer
      })
    );
    return error;
  };
  
  function saveByteArray(byte) {
    let fileName = 'Document';
    const title = getFormName();
    if (title) {
      // Replace spaces with underscores and capitalize the first letter
      fileName = title.replace(/ /g, "_").toLowerCase();  // Replace all spaces with underscores
      fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1);  // Capitalize the first character
    }
    const blob = new Blob([byte], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  const changeInNumber = (field) => {
    if (
      field?.height &&
      typeof field?.height === "string" &&
      field?.height?.includes("px")
    ) {
      return Number(
        field?.height.split("px")[0] || field?.height.split("PX")[0]
      );
    }
    return field?.height;
  };

  // Function to measure text width
  function measureTextWidth(text, fontSize, font) {
    const averageCharWidth = fontSize * 0.5; 
    return text.length * averageCharWidth;
  }
  
  // Function to wrap text
  function wrapText(text, font, fontSize, maxWidth) {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';
  
    for (const word of words) {
      const testLine = currentLine + (currentLine ? ' ' : '') + word;
      const width = measureTextWidth(testLine, fontSize, font);
  
      if (width > maxWidth && currentLine) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    }
    
    if (currentLine) {
      lines.push(currentLine);
    }
  
    return lines;
  }

  function dataURLtoFile(dataurl, filename) {
    const raw = atob(dataurl.split(",")[1]);
    const rawLength = raw.length;

    const array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i += 1) {
      array[i] = raw.charCodeAt(i);
    }

    return array;
  }

  const handleDownload = async (isPdfGenerate = false) => {
    if (backyCheckDetails && backyCheckDetails.npcs_pdf_url) {
      const url = backyCheckDetails.npcs_pdf_url;
      const pdfUrl = downloadFile(url);
      const response = await fetch(pdfUrl);      
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

      const pages = pdfDoc.getPages();
      let formData = [];
      if(isPdfGenerate){
        formData = formInputs;
      } else{
        formData = formType === BackycheckFormType.AMICI ? preFillData.amici : preFillData.npcs;
      }
      formData.forEach((element) => {
        if(!element.isRecruiter){
          element.fields.forEach(async (field) => {
            if (
              (field.fieldType === FieldTypes.TEXT_INPUT ||
                field.fieldType === FieldTypes.DATES) &&
              field.textValue
            ) {
              let textData = field.textValue;
              if (field.fieldType === FieldTypes.DATES) {
                textData = formatDate(field.textValue);
                const backgroundColor = rgb(1, 1, 1);
                pages[field?.page - 1].drawRectangle({
                  x:
                    field.positionFromParentLeft -
                    (field.maxWidth - field.pageWidth) / 2,
                  y: field.pagePositionY - changeInNumber(field) / 2 - 10,
                  width: field.width,
                  height: changeInNumber(field) - 10,
                  color: backgroundColor,
                });
              }
  
              let font = helveticaFont,
                fontSize = field.fontSize,
                maxWidth = field.width;
  
              const text = textData;
              const lines = wrapText(text, font, fontSize, maxWidth);
  
              let y = field.pagePositionY - changeInNumber(field) / 2 - 5;
  
              for (const line of lines) {
                pages[field?.page - 1].drawText(line, {
                  x:
                    field.positionFromParentLeft -
                    (field.maxWidth - field.pageWidth) / 2,
                  y: y,
                  size: fontSize,
                  font: font,
                  color: rgb(0, 0, 0),
                });
                y -= fontSize + 2;
              }
            }
  
            if (field.fieldType === FieldTypes.CHECKBOX) {
              const form = pdfDoc.getForm();
              const checkBox = form.createCheckBox(
                `some.checkBox.checked${field.id}`
              );  
              checkBox.addToPage(pages[field?.page - 1], {
                x:
                  field.positionFromParentLeft -
                  (field.maxWidth - field.pageWidth) / 2,
                y: field.pagePositionY - changeInNumber(field),
                width: field.width,
                height: changeInNumber(field),
                textColor: rgb(0, 0, 1),
                borderColor: rgb(0, 0, 1),
                borderWidth: 2,
              });
              checkBox.check();
              checkBox.enableReadOnly();
            }
  
            if (
              field.fieldType === FieldTypes.INITIALS ||
              field.fieldType === FieldTypes.SIGNATURE
            ) {              
              const data = dataURLtoFile(field.base64Image, "hello.png");
              const jpgImage = await pdfDoc.embedPng(data);
              pages[field?.page - 1].drawImage(jpgImage, {
                x:
                  field.positionFromParentLeft -
                  (field.maxWidth - field.pageWidth) / 2,
                y: field.pagePositionY - changeInNumber(field),
                width: field.width,
                height: changeInNumber(field),
              });
            }
          });
        }
      });
      const pdfBytes = await pdfDoc.save();

      const bytes = pdfBytes.length; // Exact size in bytes
      let fileDetails = {
        size: '0 bytes',
        type: 'application/pdf', 
      };
      if (bytes < 1024) {
        fileDetails.size = `${bytes} bytes`;
      } else if (bytes < 1024 * 1024) {
        fileDetails.size = `${(bytes / 1024).toFixed(1)} KB`;
      } else {
        fileDetails.size = `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
      }
      if(isPdfGenerate){
        let pdfFilePath = `media/documentSigning/backycheck_form/${backyCheckDetails.id}/${formType === BackycheckFormType.AMICI ? "amici" : "npcs"}/admin/${formType === BackycheckFormType.AMICI ? BackyCheckFormName.AMICI_FORM_NAME.replace(/ /g, '_') : BackyCheckFormName.NPCS_FORM_NAME.replace(/ /g, '_')}_${Date.now()}.pdf`;
        const uploadResult = await uploadFormPDF(pdfBytes, pdfFilePath, fileDetails);
        return uploadResult;
      }
      saveByteArray(pdfBytes);
      return true;
    }
    if(isPdfGenerate){
      return {location: null};
    }
    return false;
  };

  const handleSubmit = async () => {
    const error = validateFields();
    if (error.length > 0) {
      dispatch(ShowAlert('Please fill in missing fields', "error"));
      return;
    }
    const pdf_url = await handleDownload(true);
    const response = await updateBackycheckPolicyForm({form_data: formInputs, form_type: formType, pdf_url: pdf_url.location, uuid: id}, true);

    if (response.data.success) {
      setIsLoading(false);
      window.location.href = `${process.env.REACT_APP_URL}${ADMIN_FORM_LIST_URL}`;
    }else{
      setIsLoading(false);
    }
  };
  return (
    <>
      <Alerts />
      <Container>
        {backyCheckDetails && backyCheckDetails.id && (
          <>
          <Row className={`${styles.heading}`}>
            <Col lg={12} md={12} sm={12} className={styles.heading_sticky1}>
              <Row>
                <Col lg={!isFormFilled ? 9 : 10} md={!isFormFilled ? 9 : 10} sm={12}>
                  <div><h3>{getFormName()}</h3></div>
                </Col>
                {isFormFilled  &&  <Col lg={2} md={2} sm={12}>
                  <div className={styles.heading_buttons_div}>
                    <Box className={`button-primary`}>
                      <Button className={styles.heading_button_primary} onClick={()=>{handleDownload()}}>Download</Button>
                    </Box>
                  </div>
                </Col>}
              </Row>
            </Col>
          </Row>
            <Row>
            { isLoading && (<Col lg={12} md={12} sm={12}>
              <div style={{ height: "10vh", width: "100%" }}>
                <Box className="loader"></Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    alignItems: "center",
                    gap: "0.8rem",
                  }}
                >
                  <CircularProgress />
                  <span>Submitting ...</span>
                </div>
              </div>
              </Col>)
              }
              <Col lg={12} md={12} sm={12}>

                <div className="receiver-area" style={{ marginTop: '40px' }}>
                  <AdminPDFView formInputs={formInputs} setFormInputs={setFormInputs} preFillData={preFillData.npcs} isFormFilled={isFormFilled} backyCheckDetails={backyCheckDetails}/>
                </div>
              </Col>

              {!isFormFilled  &&  <Col lg={12} md={12} sm={12}>
                  <div className={`${styles.heading_buttons_div} w-full mt-5 mb-5`}>
                    {/* <Box className={`button-primary`}>
                      <Button className={styles.heading_button_primary}>Previous</Button>
                    </Box>
                    <Box className={`button-primary`}>
                      <Button className={styles.heading_button_primary}>Next</Button>
                    </Box> */}
                    <Box className={`button-primary w-25`}>
                      <Button className={`${styles.heading_button_primary}`} style={{width: '100%', fontSize: '1.2rem'}} onClick={handleSubmit} disabled={isLoading}>Submit</Button>
                    </Box>
                  </div>
                </Col>}
            </Row></>
        )}
      </Container>
    </>
  );
}

export default AdminDocSign;
