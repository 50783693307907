import { Grid, Box, Typography, Button, Tooltip } from "@mui/material";

import React, { useEffect, useState } from "react";
import SelectSection from "../../common/single-candidate";
import styles from "./styles.module.scss";
import { ReactComponent as Girl } from "../../../assets/candidates/girl.svg";
import boy from "../../../assets/sidebar/boy.svg";
import SingleCandidateTabel from "../single-candidate-table";
import PopupForm from "../single-candidate-model/index";
import ReffernceAdd from "../reffernce-add/index";
import ReffernceAdd2 from "../reference/index";
import CertificateAdd from "../certificates-add/index";
import Interview from "../popup-interview/index";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../../routes";
import { Navigate, useParams } from "react-router-dom";
import { ShowCandidateFunc } from "../../../store/actions/recruiterCandidateActions";
import {
  getAllRecruiterCandidateCertificates,
  getAllRecruiterCandidateCertificates2,
  getAllRecruiterCandidateForms,
  getAllRecruiterCandidateForms1,
  getAllRecruiterCandidateForms2,
  getAllRecruiterCandidateForms3,
  getAllRecruiterCandidateReff,
  getAllRecruiterInterview,
  resetSingleCandidateReff,
} from "../../../store/actions/singleCandidateScreenActions";
import { AiOutlinePlus } from "react-icons/ai";
import {
  getCandidateFormDetail,
  getCandidateFormDetail2,
  getCandidateFormDetail3,
  getCandidateFormDetail4,
} from "../../../store/actions/applyJobActions";
import ViewResume from "../upload-resume/ViewResume";
import { showResume } from "../../../api/uploadFile/uploadFile.class";
import useWindowDimensions from "../../../customHooks/useWindowDimensions";
import InfoIcon from "../../../assets/dashboard/message_green.svg";
import RejectMsg from "../formbuilder-preview/Model/rejectMsg";
import { useNavigate } from "react-router-dom";
import TextCreditPopup from "../TextCredit-Model/TextCredit-Popup";
import CustomModel from "../../candidates/single-candidate-model";
import { TbAlertCircleFilled } from "react-icons/tb";
import { ReactComponent as LinkedInIcon } from "../../../assets/candidates/linkedin.svg";
import { getBackycheckDetails } from "../../../api/backycheck/backycheck.class";

const Candidate = () => {
  const [open, setOpen] = useState(false);
  const [openReff, setOpenReff] = useState(false);
  const [openReff2, setOpenReff2] = useState(false);
  const [backyCheckDetails, setBackyCheckDetails] = useState();

  const [openCertificate, setOpenCertificate] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const candidate = useSelector((state) => state?.rcandidate);
  const auth = useSelector((state) => state?.auth);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const singleCandidate2 = useSelector((state) => state?.singleCandidate);
  const [Openmodel, setOpenmodel] = useState(false);
  const [openTextCredit, setOpenTextCredit] = useState(false);
  const candidateID = JSON?.parse(localStorage?.getItem("candidateID"));
  const applyjobs = useSelector((state) => state?.applyJob);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [viewResume, setViewResume] = useState(false);
  const navigate = useNavigate();

  const ShowJob = async (e) => {
    navigate(
      `${routes.PREVIEW_CANDIDATE_FORM}/${e}/${candidate?.showCandidate?.results?.id}`
    );
  };

  // console.log('singleCandidate2?.singleCandidateCertificates2?.results?.data', singleCandidate2?.singleCandidateCertificates2?.results?.data);

  const uuid = useParams().uuid;
  useEffect(() => {
    dispatch(ShowCandidateFunc(uuid));
    dispatch(
      getCandidateFormDetail({
        uuid: uuid,
        recruiter_id: auth?.results?.recruiter_id,
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
        form_category_name: "Job Offer Letter",
      })
    );
    dispatch(
      getCandidateFormDetail2({
        uuid: uuid,
        recruiter_id: auth?.results?.recruiter_id,
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
        form_category_name: "Application Form",
      })
    );
    dispatch(
      getCandidateFormDetail3({
        uuid: uuid,
        recruiter_id: auth?.results?.recruiter_id,
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
        form_category_name: "Induction Form",
      })
    );
    dispatch(
      getCandidateFormDetail4({
        uuid: uuid,
        recruiter_id: auth?.results?.recruiter_id,
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
        form_category_name: "Miscellaneous Form",
      })
    );
    const getBackycheckDetailsAPI = async () => {
      try {
        const response = await getBackycheckDetails().then(function (res) {
          console.log("response from the api", res);
          
          return res.data.results;
        });
        setBackyCheckDetails(response);
      } catch (error) {
        console.log(error);
      }
    };
  getBackycheckDetailsAPI();
  }, [uuid, dispatch]);

  // Job Offer Letter
  // Application Form
  // Induction Form
  // Miscellenous Form

  useEffect(() => {
    dispatch(
      getAllRecruiterCandidateCertificates({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
      })
    );

    dispatch(
      getAllRecruiterCandidateCertificates2({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
        automated_list: 1,
        is_background_check: 1,
      })
    );

    dispatch(
      getAllRecruiterCandidateReff({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
      })
    );

    dispatch(
      getAllRecruiterInterview({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        workspace_id: auth?.results?.workspace_id,
      })
    );

    dispatch(
      getAllRecruiterCandidateForms({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        form_builder_category_name: "Induction Form",
      })
    );
    dispatch(
      getAllRecruiterCandidateForms1({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        form_builder_category_name: "Miscellaneous Form",
      })
    );
    dispatch(
      getAllRecruiterCandidateForms2({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        form_builder_category_name: "Job Offer Letter",
      })
    );
    dispatch(
      getAllRecruiterCandidateForms3({
        candidate_id: candidate?.showCandidate?.results?.id || candidateID,
        form_builder_category_name: "Application Form",
      })
    );
  }, []);

  useEffect(() => {
    if (singleCandidate?.editCertificate?.success) {
      setOpenCertificate(true);
      if (
        singleCandidate2?.editCertificate?.results
          ?.industry_certification_license?.is_automated === 1
      ) {
        setBackgroundCheck(true);
      } else {
        setBackgroundCheck(false);
      }
    }
  }, [singleCandidate?.editCertificate]);

  useEffect(() => {
    if (singleCandidate?.editInterView?.success) {
      setOpen(true);
    }
  }, [singleCandidate?.editInterView]);

  // if (!candidate.showCandidate?.results) {
  //   return <Navigate to={routes.ALL_CANDIDATES} replace />;
  // }
  const information = [
    {
      label: `Email Address`,
      ans: candidate?.showCandidate?.results?.email,
      type: 'text'
    },
    {
      label: `Contact Number`,
      ans: candidate?.showCandidate?.results?.contact_number,
      type: 'text'
    },

    {
      label: `Organization`,
      ans:
        candidate?.showCandidate?.results?.candidate_detail?.company_name || "",
        type: 'text'
    },
    {
      label: `Job Role`,
      ans: candidate?.showCandidate?.results?.candidate_detail?.job_title || "",
      type: 'text'
    },
    {
      label: `LinkedIn`,
      ans: candidate?.showCandidate?.results?.social_network?.linkedin || "",
      type: 'link'
    },
  ];

  return (
    <Box className={styles.candidate}>
      <Grid container spacing={2}>
        <Grid item md={8} lg={8} xs={12}>
          <Box className={styles.header}>
            <Typography variant="h4">Candidate</Typography>
            <Typography>
              {candidate?.showCandidate?.results?.worecs_id?.toUpperCase()}
            </Typography>
            {width < 901 && (
              <Box className={styles.profileBox}>
                <Box className={styles.imageBox}>
                  <Box className={styles.imageBox2}>
                    <Box className={styles.imageContainer}>
                      <img
                        src={
                          candidate?.showCandidate?.results?.profile_image
                            ? `${process.env.REACT_APP_URL}${candidate?.showCandidate?.results?.profile_image}`
                            : boy
                        }
                        alt="Recruiter"
                      />
                    </Box>
                    <Box className={styles?.imageBox}>
                      {candidate?.showCandidate?.results?.candidate_resume && (
                        <Typography
                          className={styles?.a}
                          onClick={() => setViewResume(true)}
                        >
                          View Resume
                        </Typography>
                      )}
                      {candidate?.showCandidate?.results?.candidate_resume && (
                        <a
                          // href={`data:application/pdf;base64,${candidate?.showCandidate?.results?.candidate_resume?.resume_path}`}
                          href={`${process.env.REACT_APP_URL}${candidate?.showCandidate?.results?.candidate_resume?.resume_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={`data:application/pdf;base64,${candidate?.showCandidate?.results?.candidate_resume?.preview_resume_path}`}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Download
                        </a>
                      )}
                    </Box>
                  </Box>
                  <Typography variant="h5" style={{ paddingLeft: "0px" }}>
                    {" "}
                    {candidate?.showCandidate?.results?.first_name +
                      " " +
                      candidate?.showCandidate?.results?.last_name || "Name"}
                  </Typography>
                </Box>
                <Box className={styles.info}>
                  {console.log('information', information)
                  }
                  {information?.map(
                    (e, i) =>
                      {
                        if(e.ans !== ""){
                          if(e.type === 'link'){
                            return (<Box className={styles.infoSection} key={i}>
                              <Typography variant="h5">{e?.label}</Typography>
                              <div onClick={() => window.open(e?.ans, "_blank")}>
                              {e?.ans ? <LinkedInIcon /> : <></>}
                              </div>
                            </Box>)
                          }else{
                            return (<Box className={styles.infoSection} key={i}>
                              <Typography variant="h5">{e?.label}</Typography>
                              <Typography style={{ margin: "10px 0" }}>
                              {e?.ans}
                              </Typography>
                            </Box>)
                          }
                        }
                        
                      }
                  )}
                </Box>
              </Box>
            )}
            <Box className={styles.calendarBox}>
              <Box className="d-flex align-items-center justify-content-between">
                <Typography variant="h5" className={styles.offerletterHeading}>
                  Job Offer Letter
                </Typography>
                {candidate?.showCandidate?.results
                  ?.remaining_text_notifications === 0 ? (
                  candidate?.showCandidate?.results?.job_offer_letter_count >
                  2 ? (
                    <Box
                      className="d-inline-flex align-items-center mt-1"
                      onClick={() => {
                        setOpenTextCredit(true);
                      }}
                      style={{
                        border: "1px solid #dc3545",
                        padding: "6px",
                        borderRadius: "8px",
                      }}
                    >
                      <p
                        className="m-0 text-danger"
                        style={{ cursor: "pointer", fontSize: "14px" }}
                      >
                        Text notification credits are finished
                      </p>
                      <TbAlertCircleFilled
                        color="#dc3545"
                        className="ml-2"
                        fontSize={"20px"}
                      />
                    </Box>
                  ) : null
                ) : null}
              </Box>
              <div className={styles.offerrow}>
                <Box
                  className={`${styles.buttons} ${styles.offerltterbutton} button-primary`}
                >
                  <button
                    // className={styles.addTabel}
                    onClick={() => {
                      ShowJob(uuid);
                    }}
                  >
                    Send Letter of Offer
                  </button>
                </Box>

                <Box>
                  <span>Status: </span>
                  {applyjobs?.candidateForm1?.results?.response ===
                  "Pending" ? (
                    <span className="text-warning">
                      {applyjobs?.candidateForm1?.results?.response}
                    </span>
                  ) : applyjobs?.candidateForm1?.results?.response ===
                    "Completed" ? (
                    <span className="text-green">
                      {applyjobs?.candidateForm1?.results?.response}
                    </span>
                  ) : applyjobs?.candidateForm1?.results?.response ===
                    "Rejected" ? (
                    <span className="text-red">
                      {
                        <span
                          className="text-danger"
                          onClick={() => {
                            setOpenmodel(true);
                          }}
                        >
                          Job offer rejected
                          <Tooltip title="Reason for rejection">
                            <img
                              className="ml-2"
                              style={{ cursor: "pointer" }}
                              src={InfoIcon}
                              alt="info"
                            />
                          </Tooltip>
                        </span>
                      }
                    </span>
                  ) : (
                    <span>No offer</span>
                  )}
                </Box>
                <RejectMsg
                  openModal={Openmodel}
                  setOpenModal={setOpenmodel}
                  olddata={applyjobs?.candidateForm1?.results?.reason}
                />
                <Box>
                  <span>
                    {singleCandidate?.singleCandidateForm2?.results?.data[0]
                      ?.pdf_url === undefined ? (
                      ""
                    ) : (
                      <a
                        rel="noreferrer"
                        href={`${process.env.REACT_APP_URL}${singleCandidate?.singleCandidateForm2?.results?.data[0]?.pdf_url}`}
                        target="_blank"
                      >
                        View Offer
                      </a>
                    )}
                  </span>
                </Box>
              </div>
            </Box>
            {/* <Box className={styles.calendarBox}>
                            <Box className={styles.selectBox1}>
                                <SelectSection
                                    title={'Job Offer Letter'}
                                    cata={'Job Offer Letter'}
                                    results={applyjobs?.candidateForm1}
                                    placeholder="Select offer letter"
                                    uuid={uuid}
                                    
                                    des={'Select a form from the dropdown menu '}
                                    des2={`You have sent the offer letter to candidate.`}
                                    id={1}
                                />
                            </Box>
                            <SingleCandidateTabel
                                className={styles.titleth}
                                titles={['Form Name', 'Request Date', 'Status', 'Actions']}
                                value={6}
                                rows={singleCandidate?.singleCandidateForm2?.results?.data}
                            />
                        </Box> */}
            <Box className={`${styles.calendarBox} `}>
              <Box
                className={`${styles.selectBox2} d-flex justify-content-between align-items-start`}
              >
                <SelectSection
                  title={"Application Form"}
                  cata={"Application Form"}
                  placeholder="select application form"
                  results={applyjobs?.candidateForm2}
                  uuid={uuid}
                  des={
                    "Select any one application form from the options below. if the relevant form is Not Available, kindly go back to forms tab & create or upload a new one."
                  }
                  des2={`You have sent the  application form to candidate.`}
                  id={2}
                />
                {candidate?.showCandidate?.results
                  ?.remaining_text_notifications === 0 ? (
                  candidate?.showCandidate?.results?.application_form_count >
                  2 ? (
                    <Box
                      className="d-inline-flex align-items-center mt-1"
                      onClick={() => {
                        setOpenTextCredit(true);
                      }}
                      style={{
                        border: "1px solid #dc3545",
                        padding: "6px",
                        borderRadius: "8px",
                      }}
                    >
                      <p
                        className="m-0 text-danger"
                        style={{ cursor: "pointer", fontSize: "14px" }}
                      >
                        Text notification credits are finished
                      </p>
                      <TbAlertCircleFilled
                        color="#dc3545"
                        className="ml-2"
                        fontSize={"20px"}
                      />
                    </Box>
                  ) : null
                ) : null}

                <CustomModel
                  open={openTextCredit}
                  setOpen={setOpenTextCredit}
                  disableCross={true}
                >
                  <TextCreditPopup
                    open={openTextCredit}
                    setOpen={setOpenTextCredit}
                  />
                </CustomModel>
              </Box>
              <SingleCandidateTabel
                className={styles.titleth}
                titles={["Form Name", "Request Date", "Status", "Actions"]}
                value={7}
                rows={singleCandidate?.singleCandidateForm3?.results?.data}
                results={applyjobs?.candidateForm2}
              />
            </Box>

            {/* <Box className={styles.selectBox1}>
                            <SelectSection
                                title={'Job Offer Letter'}
                                cata={'Job Offer Letter'}
                                results={applyjobs?.candidateForm1}
                                placeholder="Select offer letter"
                                uuid={uuid}
                                des={'Select a form from the dropdown menu '}
                                des2={`You have sent the offer letter to candidate.`}
                                id={1}
                            />
                        </Box> */}
            {/* <Box className={styles.selectBox2}>
                            <SelectSection
                                title={'Application Form'}
                                cata={'Application Form'}
                                placeholder="select application form"
                                results={applyjobs?.candidateForm2}
                                uuid={uuid}
                                des={
                                    'Select any one application form from the options below. if the relevant form is Not Available, kindly go back to forms tab & create or upload a new one.'
                                }
                                des2={`You have sent the  application form to candidate.`}
                                id={2}
                            />
                        </Box> */}
          </Box>
        </Grid>
        <Grid item md={4} lg={4} xs={12}>
          {width > 901 && (
            <Box className={styles.profileBox}>
              <Box className={styles.imageBox}>
                <Box className={styles.imageBox2} paddingLeft={2.5}>
                  <Box className={styles.imageContainer}>
                    <img
                      src={
                        candidate?.showCandidate?.results?.profile_image
                          ? `${process.env.REACT_APP_URL}${candidate?.showCandidate?.results?.profile_image}`
                          : boy
                      }
                      alt="Recruiter"
                    />
                  </Box>
                  <Box className={styles?.imageBox}>
                    {candidate?.showCandidate?.results?.candidate_resume && (
                      <Typography
                        className={styles?.a}
                        onClick={() => setViewResume(true)}
                      >
                        View Resume
                      </Typography>
                    )}
                    {candidate?.showCandidate?.results?.candidate_resume && (
                      <a
                        // href={`data:application/pdf;base64,${candidate?.showCandidate?.results?.candidate_resume?.resume_path}`}
                        href={`${process.env.REACT_APP_URL}${candidate?.showCandidate?.results?.candidate_resume?.resume_path}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={`data:application/pdf;base64,${candidate?.showCandidate?.results?.candidate_resume?.preview_resume_path}`}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Download
                      </a>
                    )}
                  </Box>
                </Box>
                <Typography variant="h5">
                  {" "}
                  {candidate?.showCandidate?.results?.first_name +
                    " " +
                    candidate?.showCandidate?.results?.last_name || "Name"}
                </Typography>
              </Box>
              <Box className={styles.info}>
                {information?.map(
                  (e, i) =>
                    {
                      if(e.ans !== ""){
                        if(e.type === 'link'){
                          return (<Box className={styles.infoSection} key={i}>
                            <Typography variant="h5">{e?.label}</Typography>
                            <div onClick={() => window.open(e?.ans, "_blank")}>
                            {e?.ans ? <LinkedInIcon /> : <></>}
                            </div>
                          </Box>)
                        }else{
                          return (<Box className={styles.infoSection} key={i}>
                            <Typography variant="h5">{e?.label}</Typography>
                            <Typography>
                            {e?.ans}
                            </Typography>
                          </Box>)
                        }
                      } 
                    }
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box className={styles.tabelSection}>
        <Box className={styles.table1}>
          <Box className={styles.table1a}>
            <Box className="d-flex align-items-center">
              <Typography variant="h4">References</Typography>
              {candidate?.showCandidate?.results
                ?.remaining_text_notifications === 0 ? (
                candidate?.showCandidate?.results?.candidate_reference_count >
                4 ? (
                  <Box
                    className="d-inline-flex align-items-center mt-1 ml-2"
                    onClick={() => {
                      setOpenTextCredit(true);
                    }}
                    style={{
                      border: "1px solid #dc3545",
                      padding: "6px",
                      borderRadius: "8px",
                    }}
                  >
                    <p
                      className="m-0 text-danger"
                      style={{ cursor: "pointer", fontSize: "14px" }}
                    >
                      Text notification credits are finished
                    </p>
                    <TbAlertCircleFilled
                      color="#dc3545"
                      className="ml-2"
                      fontSize={"20px"}
                    />
                  </Box>
                ) : null
              ) : null}
            </Box>

            <Box className={`${styles.buttons} button-primary`}>
              <button
                // className={styles.addTabel}
                onClick={() => {
                  setOpenReff(true);
                  dispatch(resetSingleCandidateReff());
                }}
              >
                Request References from Candidate
              </button>
              <button
                // className={styles.addTabel}
                className="secondary-btn"
                onClick={() => {
                  setOpenReff2(true);
                  dispatch(resetSingleCandidateReff());
                }}
              >
                Add References Manually
              </button>
              {/* <AiOutlinePlus /> */}
            </Box>
          </Box>
          <SingleCandidateTabel
            uuid={uuid}
            titles={[
              "ID",
              " Name",
              "Phone Number",
              "Email Address",
              " Job Title",
              " Status",
              "Referee IP Address",
              "Actions",
            ]}
            value={1}
            rows={singleCandidate?.singleCandidateReff?.results?.data}
          />
        </Box>
        <Box className={styles.table1}>
          <Box className={styles.table1a}>
            <Typography variant="h4">Licenses & Certification</Typography>
            <Box
              className={`${styles.buttons} button-primary`}
              onClick={() => {
                dispatch(resetSingleCandidateReff());
                setOpenCertificate(true);
                setBackgroundCheck(false);
              }}
            >
              {/* <AiOutlinePlus /> */}
              <button className=""> request for License Copy </button>
            </Box>
          </Box>
          <div className={styles.linceseTable}>
            <Typography variant="p" className="mt-2 d-block">
              License Copy
            </Typography>
            <SingleCandidateTabel
              className={styles.titleth}
              titles={[
                "Industries Name",
                "License Name",
                "Request Date",
                "Candidate Response",
                "Actions",
              ]}
              value={2}
              rows={singleCandidate?.singleCandidateCertificates?.results?.data}
            />
          </div>

          <div className={styles.linceseTable1}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={styles.linceseTable2}>
                <Typography variant="p">Background Checks</Typography>
              </div>
              <Box
                className={`${styles.buttons} button-primary `}
                onClick={() => {
                  dispatch(resetSingleCandidateReff());
                  setOpenCertificate(true);
                  setBackgroundCheck(true);
                }}
              >
                <button className="addmorebtn">
                  {" "}
                  request for Background Checks{" "}
                </button>
              </Box>
            </Box>
            <SingleCandidateTabel
              className={styles.titleth}
              titles={[
                "Background Check",
                "Request Date",
                "Verification Status",
                "Actions",
              ]}
              value={2}
              isBackgroundTable={true}
              rows={
                singleCandidate2?.singleCandidateCertificates2?.results?.data
              }
            />
          </div>
        </Box>
      </Box>
      <Box className={styles.calendarBox}>
        <Box className={styles.selectBox1}>
          <SelectSection
            cata={"Induction Form"}
            placeholder={"select induction form"}
            results={applyjobs?.candidateForm3}
            uuid={uuid}
            id={3}
            title={"Induction Form"}
            des={
              "Select any one application form from the options below. if the relevant form is Not Available, kindly go back to forms tab & create or upload a new one."
            }
          />
        </Box>
        <SingleCandidateTabel
          className={styles.titleth}
          titles={["Form Name", "Request Date", "Status", "Actions"]}
          value={4}
          rows={singleCandidate?.singleCandidateForm?.results?.data}
          results={applyjobs?.candidateForm3}
        />
      </Box>
      <Box className={styles.calendarBox}>
        <Box className={styles.selectBox2}>
          <SelectSection
            title={"Miscellaneous Form"}
            cata={"Miscellaneous Form"}
            results={applyjobs?.candidateForm4}
            uuid={uuid}
            placeholder={"Select miscellaneous form"}
            id={4}
            des={`Select any one application form from the options below. if the relevant form is Not Available, kindly go back to forms tab & create or upload a new one.`}
          />
        </Box>
        <SingleCandidateTabel
          className={styles.titleth}
          titles={["Form Name", "Request Date", "Status", "Actions"]}
          value={5}
          rows={singleCandidate?.singleCandidateForm1?.results?.data}
          results={applyjobs?.candidateForm4}
        />
      </Box>
      <Box className={styles.list}>
        {/* <Box className={styles.selectBox1}>
                    <SelectSection
                        cata={'Induction Form'}
                        placeholder={'select induction form'}
                        results={applyjobs?.candidateForm3}
                        uuid={uuid}
                        id={3}
                        title={'Induction Form'}
                        des={
                            'Select any one application form from the options below. if the relevant form is Not Available, kindly go back to forms tab & create or upload a new one.'
                        }
                    />
                </Box> */}
        {/* <Box className={styles.selectBox2}>
                    <SelectSection
                        title={'Miscellaneous Form'}
                        cata={'Miscellaneous Form'}
                        results={applyjobs?.candidateForm4}
                        uuid={uuid}
                        placeholder={'Select miscellaneous form'}
                        id={4}
                        des={`Select any one application form from the options below. if the relevant form is Not Available, kindly go back to forms tab & create or upload a new one.`}
                    />
                </Box> */}

        <Box className={styles.calendarBox}>
          <Typography variant="h4">Interviews</Typography>
          <Typography variant="h6">Click below to Schedule</Typography>
          <Box className={`button-primary ${styles.button}`}>
            <Button
              onClick={() => {
                dispatch(resetSingleCandidateReff());
                setOpen(true);
              }}
            >
              Schedule
            </Button>
          </Box>
          <SingleCandidateTabel
            titles={[
              "Subject",
              "Time",
              "Date",
              " Location",
              "Status",
              "Actions",
            ]}
            rows={singleCandidate?.singleCandidateInterview?.results?.data}
            value={3}
            interview={true}
          />

          <PopupForm open={open} setOpen={setOpen} disableCross={true}>
            <Interview open={open} setOpen={setOpen} />
          </PopupForm>
          <PopupForm
            open={openCertificate}
            setOpen={setOpenCertificate}
            disableCross={true}
          >
            <CertificateAdd
              open={openCertificate}
              setOpen={setOpenCertificate}
              backgroundCheck={backgroundCheck}
              setBackgroundCheck={setBackgroundCheck}
              backyCheckDetails={backyCheckDetails}
            />
          </PopupForm>
          <PopupForm open={openReff} setOpen={setOpenReff} disableCross={true}>
            <ReffernceAdd open={openReff} setOpen={setOpenReff} />
          </PopupForm>

          <ReffernceAdd2 open={openReff2} setOpen={setOpenReff2} />
          <ViewResume
            openModal={viewResume}
            setOpenModal={setViewResume}
            resume={candidate?.showCandidate?.results?.candidate_resume}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Candidate;
