import React from "react";
import CustomModel from "../candidates/single-candidate-model/index";
import styles from "./styles.module.scss";

function PhotoPopup({
    isPhotoOpen,
    setIsPhotoOpen,
    imageUrl,
}) {
  return (
    <CustomModel open={isPhotoOpen} setOpen={setIsPhotoOpen} setHeight={true}>
        <div className={styles.largeImageDiv}>
        <img src={imageUrl} className={styles.largeImage} alt={imageUrl} />
      </div>
    </CustomModel>
  );
}

export default PhotoPopup;
