import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
} from "@mui/material";

const InterviewPopup = ({ opne, setOpen, data, name, certificate }) => {
  return (
    <Box className="interviewParrent">
    <Typography variant="h4" className="align-center mt-3 w-100" style={{lineHeight: '2rem',  textTransform: 'capitalize'}}>
      {name || "Reference Data"}
    </Typography>
    {data?.third_party_name === "backycheck" && (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}  style={{ width: "100%" }}>
          <Typography variant="h6" component="h6" className="mb-2">
            Person Detail
          </Typography>
          <table style={{ width: "100%" }}>
            <tr>
              <th>Order ID</th>
              <td>{data?.verification_data?.orderDetails?.orderID}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>
                {data?.verification_data?.orderDetails?.firstName}{" "}
                {data?.verification_data?.orderDetails?.surname}
              </td>
            </tr>
            <tr>
              <th>DOB</th>
              <td>{data?.verification_data?.DOB}</td>
            </tr>
          </table>
        </Grid>

        <Grid item xs={12} lg={12}  style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <tr>
              <th>Status</th>
              <td>
                {data?.verification_data?.orderServices?.[0]?.checkStatus !==
                null
                  ? data?.verification_data?.orderServices?.[0]?.checkStatus
                  : "-"}
              </td>
            </tr>
          </table>
        </Grid>
      </Grid>
    )}
    {((data?.verification_data?.visa || data?.verification_data?.orderDetails) || (!data?.is_visa && !data?.is_backy_check)) && (<>
        <Grid item xs={12} lg={12}  style={{ width: "100%" }}>
        <Typography variant="h6" component="h6" className="mb-2">
          Personal Detail
        </Typography>
        <table style={{ width: "100%" }}>
          <tr>
            <th>Name</th>
            <td>{data?.verification_data?.document?.given_name} {data?.verification_data?.document?.family_name}</td>
          </tr>
          <tr>
            <th>DOB</th>
            <td>{data?.verification_data?.document?.date_of_birth ? (data?.verification_data?.document?.date_of_birth).split("-").reverse().join("-") : "-"}</td>
          </tr>
          <tr>
            <th>Nationality</th>
            <td>{data?.verification_data?.document?.country}</td>
          </tr>
          <tr>
            <th>Passport ID</th>
            <td>{data?.verification_data?.document?.identifier}</td>
          </tr>
        </table>
      </Grid>
      <Grid item xs={12} lg={12} style={{width: '100%'}}>
        <Typography variant="h6" component="h6" className="mb-2">
          Visa Details
        </Typography>
        <table style={{ width: "100%" }}>
          <tr>
            <th>Visa Type Name</th>
            <td>{data?.verification_data?.visa?.australia?.type_name}</td>
          </tr>
          <tr>
            <th>Visa Type Details</th>
            <td>
              {data?.verification_data?.visa?.australia?.type_details}
            </td>
          </tr>
          <tr>
            <th>Visa Applicant</th>
            <td>{data?.verification_data?.visa?.australia?.applicant}</td>
          </tr>
          <tr>
            <th>Work Entitlement</th>
            <td>{data?.verification_data?.visa?.australia?.work_entitlement}</td>
          </tr>
          <tr>
            <th>Entitlement Description</th>
            <td>{data?.verification_data?.visa?.australia?.entitlement_description}</td>
          </tr>
        </table>
      </Grid>
      <Grid item xs={12} lg={12}  style={{ width: "100%" }}>
        <table style={{ width: "100%" }}>
          <tr>
            <th>Status</th>
            <td>
              {data?.verification_data?.status}
            </td>
          </tr>
        </table>
      </Grid></>)}
    {data?.is_visa && !data?.verification_data?.visa && (<>
      
      <Grid item xs={12} lg={12} style={{ width: "100%" }}>
      <Typography variant="h6" component="h6" className="mb-2">
        Personal Detail
      </Typography>
      <table style={{ width: "100%" }}>
      <tr>
          <th>Given Name</th>
          <td>{data?.verification_data?.given_name}</td>
        </tr>
        <tr>
          <th>Family Name</th>
          <td>{data?.verification_data?.family_name}</td>
        </tr>
        <tr>
          <th>Date of Birth</th>
          <td>{data?.verification_data?.dob ? (data?.verification_data?.dob).split("-").reverse().join("-") : "-"}</td>
        </tr>
        <tr>
          <th>Document Number</th>
          <td>{data?.verification_data?.document_number}</td>
        </tr>
        <tr>
          <th>Country of Document</th>
          <td style={{textTransform: 'capitalize'}}>{data?.verification_data?.country}</td>
        </tr>
      </table>
    </Grid>
    <Grid item xs={12} lg={12} style={{width: '100%'}}>
      <Typography variant="h6" component="h6" className="mb-2">
        Visa Details
      </Typography>
      <table style={{ width: "100%" }}>
        <tr>
          <th>Category Selected</th>
          <td>{data?.verification_data?.category_selected}</td>
        </tr>
        <tr>
          <th>Family Name</th>
          <td>{data?.verification_data?.family_name}</td>
        </tr>
        <tr>
          <th>Given Name</th>
          <td>{data?.verification_data?.given_name}</td>
        </tr>
        <tr>
          <th>Document Number</th>
          <td>{data?.verification_data?.document_number}</td>
        </tr>
        <tr>
          <th>Visa Class/SubClass</th>
          <td>{data?.verification_data?.visa_class}</td>
        </tr>
        <tr>
          <th>Visa Applicant</th>
          <td>{data?.verification_data?.visa_applicant}</td>
        </tr>
        <tr>
          <th>Visa Grant Date</th>
          <td>{data?.verification_data?.visa_grant_date ? (data?.verification_data?.visa_grant_date).split("-").reverse().join("-") : "-"}</td>
        </tr>
        <tr>
          <th>Location</th>
          <td>{data?.verification_data?.location}</td>
        </tr>
        <tr>
          <th style={{verticalAlign: 'baseline'}}>Work Entitlements</th>
          <td><p style={{textAlign: 'justify', whiteSpace: 'break-spaces'}}>{data?.verification_data?.work_entitlements}</p></td>
        </tr>
      </table>
    </Grid>
    <Grid item xs={12} lg={12}  style={{ width: "100%" }}>
      <table style={{ width: "100%" }}>
        <tr>
          <th>Status</th>
          <td>
            {data?.admin_status}
          </td>
        </tr>
      </table>
    </Grid>
    </>)}
  </Box>
  );
};

export default InterviewPopup;
