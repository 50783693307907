import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactComponent as Cross } from "../../../../../assets/industries/model/x.svg";
import Modal from "@mui/material/Modal";
import styles from "./styles.module.scss";
import { Button, Grid } from "@mui/material";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  addProfileLicense,
  recruitmentIndustry,
} from "../../../../../api/candidate/candidate.class";
import CustomSelect from "../../../../common/Select";
import ImageUploader from "../../../../common/ImageUploader";
import Alerts from "../../../../common/Alert";
import TextInput from "../../../../common/TextInput";
import CountrySelect from "../../../../common/CountrySelect/CountrySelect";
import LicenseCertificateCommonForm from "./LicenseCertificateCommonForm";
import VevoCheckCommonForm from "./VevoCheckCommonForm";
import BackyCheckCommonForm from "./BackyCheckCommonForm";

export default function CustomModal({
  openModal,
  setOpenModal,
  isAddition,
  userDetailData,
  data,
}) {
  const handleClose = () => setOpenModal(false);

  const auth = useSelector((state) => state?.auth?.results);

  const [recruitmentIndustries, setRecruitmentIndustries] = useState();

  const [recruitmentOptions, setRecruitmentOptions] = useState([]);
  const [CertLicenseOptions, setCertLicenseOptions] = useState([]);
  const [licenseFormType, setLicenseFormType] = useState(0);
  const [isWWCForm, setIsWWCForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit1, setEdit1] = useState(true);
  const [edit2, setEdit2] = useState(true);
  const [file, setFile] = useState({
    front_image: data?.front_image,
    back_image: data?.back_image,
  });

  const authProfile = useSelector((state) => state?.rprofile);

  useEffect(() => {
    const API = async () => {
      try {
        const respone = await recruitmentIndustry();
        setRecruitmentIndustries(respone.data.results);
      } catch (error) {
        console.log(error);
      }
    };
    API();
  }, []);

  useEffect(() => {
    if (recruitmentIndustries?.length > 0) {
      recruitmentIndustries.forEach((record, index) => {
        const data = [{ value: record.id, label: record.name, index: index }];
        setRecruitmentOptions((prev) => [...prev, ...data]);
      });
    }
    return () => {
      setRecruitmentOptions("");
    };
  }, [recruitmentIndustries]);

  const handleRecruitment = (setFieldValue, option, values) => {
    if (values.recruit_industry === "") {
      setFieldValue("recruit_industry", option);
    } else {
      setCertLicenseOptions([]);
      setFieldValue("recruit_industry", option);
    }

    if (recruitmentOptions !== null && recruitmentIndustries?.length > 0) {
      recruitmentIndustries[
        option.index
      ]?.industry_certification_license?.forEach((record, index) => {
        const data = [{ value: record.id, label: record.name, index: index }];
        setCertLicenseOptions((prev) => [...prev, ...data]);
      });
    }
  };

  const handleCertLicense = (setFieldValue, option) => {
    setFieldValue("industry_cert_license", option);
  };

  const initialValues = {
    recruit_industry: "",
    industry_cert_license: "",
    issue_date: "",
    expiry_date: "",
    doc_id: "",
    first_name: authProfile?.profile?.results?.first_name,
    last_name: authProfile?.profile?.results?.last_name,
    dob: "",
    mail_id: authProfile?.profile?.results?.email,
    state: "",
    country: "",
    // passportNumber: "",
  };

  const licenseSchema = Yup.object().shape({
    recruit_industry: Yup.object().required("Enter Recruitment Industries"),
    industry_cert_license: Yup.object().required("Enter Certification License"),
    issue_date: Yup.string()
      .test("required_if", "Issue date is required", function (value) {
        const isRequired = [1, 3].includes(licenseFormType);
        return !isRequired || (value && value.trim() !== "");
      })
      .test(
        "futureDate",
        "Issue date cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "DD/MM/YYYY");
          const currentDate = moment();
          return !selectedDate.isBefore(currentDate);
        }
      ),
    expiry_date: Yup.string().test(
      "required_if",
      "Expiry date is required",
      function (value) {
        const isRequired =
          [1, 3].includes(licenseFormType) ||
          (licenseFormType === 2 && isWWCForm);
        return !isRequired || (value && value.trim() !== "");
      }
    ),
    doc_id: Yup.string().test(
      "required_if",
      "Document number is required",
      function (value) {
        const isRequired =
          [1, 3].includes(licenseFormType) ||
          (licenseFormType === 2 && isWWCForm);
        return !isRequired || (value && value.trim() !== "");
      }
    ),
    first_name: Yup.string().required("Enter First Name"),
    last_name: Yup.string().required("Enter Last Name"),
    dob: Yup.string()
      .required("Enter Date of Birth")
      .test(
        "futureDate",
        "Date of Birth cannot be in the future",
        function (value) {
          const selectedDate = moment(value, "DD/MM/YYYY");
          const currentDate = moment();
          return !selectedDate.isBefore(currentDate);
        }
      ),
    mail_id: Yup.string().required("Enter Email"),
    country: Yup.string().test(
      "required_if",
      "Country is required",
      function (value) {
        const isRequired = [1, 3].includes(licenseFormType);
        return !isRequired || (value && value.trim() !== "");
      }
    ),
    // doc_id: Yup.string().required("Enter Documnet ID"),
    // country: Yup.string().required("Enter Country"),
    // issue_date: Yup.string().required("Enter Issue Date"),
    // expiry_date: Yup.string().required("Enter Expiry Date"),
    state: Yup.string().test(
      "required_if",
      "State is required",
      function (value) {
        const isRequired = licenseFormType === 2 && isWWCForm;
        return !isRequired || (value && value.trim() !== "");
      }
    ),
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      var issueDate =
        values.issue_date && values.issue_date.trim() !== ""
          ? values.issue_date
          : "";
      var expiryDate =
        values.expiry_date && values.expiry_date.trim() !== ""
          ? values.expiry_date
          : "";

      const payload = {
        candidate_id: auth?.candidate_id,
        request_type: "candidate",
        document_number: values.doc_id,
        first_name: authProfile?.profile?.results?.first_name,
        last_name: authProfile?.profile?.results?.last_name,
        dob: values?.dob,
        state: values?.state,
        country: values?.country,
        // passportNumber: values?.passportNumber,
        mail_id: authProfile?.profile?.results?.email,
        issue_date: issueDate,
        expiry_date: expiryDate,
        candidate_lic_cert_id: userDetailData?.id || "",
        "recruitment_industries[recruitment_industry_1][rec_ind_request_id]":
          values.recruit_industry?.value || "",
        "recruitment_industries[recruitment_industry_1][ind_cert_lic_request_id]":
          values.industry_cert_license?.value || "",
        "recruitment_industries[recruitment_industry_1][front_image]":
          file?.front_image,
        "recruitment_industries[recruitment_industry_1][back_image]":
          file?.back_image,
        "recruitment_industries[recruitment_industry_1][issue_date]":
          issueDate || "",
        "recruitment_industries[recruitment_industry_1][expiry_date]":
          expiryDate || "",
        "recruitment_industries[recruitment_industry_1][document_number]":
          values.doc_id || "",
        "recruitment_industries[recruitment_industry_1][first_name]":
          values.first_name || "",
        "recruitment_industries[recruitment_industry_1][last_name]":
          values.last_name || "",
        "recruitment_industries[recruitment_industry_1][dob]":
          values?.dob || "",
        "recruitment_industries[recruitment_industry_1][mail_id]":
          values.mail_id || "",
        "recruitment_industries[recruitment_industry_1][state]":
          values.state || "",
        "recruitment_industries[recruitment_industry_1][country]":
          values.country || "",
      };

      const response = await addProfileLicense(payload);

      if (response.data.success) {
        handleClose();
        isAddition(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formType = (license) => {
    const vevoCheck = [
      "vevo check",
      "vevo rights to work",
      "vevo rights to work check request",
    ];
    const backyCheck = [
      "police check",
      "national police check",
      "nationally coordinated criminal check",
      "credit history report check request",
      "working with children check",
      "working with children check request (excluding act)",
    ];
    const isWWC = [
      "working with children check",
      "working with children check request (excluding act)",
    ];
    if (vevoCheck.includes(license)) {
      setLicenseFormType(1);
    } else if (backyCheck.includes(license)) {
      setLicenseFormType(2);
      setIsWWCForm(false);
      if (isWWC.includes(license)) {
        setIsWWCForm(true);
      }
    } else {
      setLicenseFormType(3);
    }
  };
  return (
    <>
      <Alerts />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`${styles.industryModel}`}
      >
        <Box className={`${styles.boxModel} xym1`}>
          <Box className={styles.modalwapper}>
            <Box className={styles.cross} onClick={handleClose}>
              <Cross />
            </Box>
            <Typography variant="h4" className={styles.heading}>
              Add Certificates and Licenses
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={licenseSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} className={styles.formsBox}>
                  {console.log("come to value---", errors)}
                  <Box className={styles.statusbox}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="recruit_industry"
                            onChange={(option) => {
                              handleRecruitment(setFieldValue, option, values);
                            }}
                            value={values.recruit_industry}
                            options={recruitmentOptions}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Industries &nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                          />
                          <span>
                            {errors.recruit_industry &&
                              touched.recruit_industry &&
                              errors.recruit_industry}
                          </span>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box className={styles.parentInput}>
                          <CustomSelect
                            customClass={styles.select}
                            type="text"
                            name="industry_cert_license"
                            onChange={(option) => {
                              handleCertLicense(setFieldValue, option, values);
                              formType(option.label.toLowerCase());
                            }}
                            value={values.industry_cert_license}
                            options={CertLicenseOptions}
                            sx={{ maxWidth: "initial" }}
                            label={
                              <>
                                Industry Certification License&nbsp;
                                <span style={{ color: "red" }}>*</span>
                              </>
                            }
                          />
                          <span>
                            {errors.industry_cert_license &&
                              touched.industry_cert_license &&
                              errors.industry_cert_license}
                          </span>
                        </Box>
                      </Grid>
                      {/* {Dynamic form of the Visa check and vevo checks} */}
                      {licenseFormType === 1 && (
                        <VevoCheckCommonForm
                          handleChange={handleChange}
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                      )}
                      {/* {Dynamic form of the backy checks checks} */}
                      {licenseFormType === 2 && (
                        <BackyCheckCommonForm
                          handleChange={handleChange}
                          values={values}
                          errors={errors}
                          touched={touched}
                          data={data}
                          isWWCForm={isWWCForm}
                        />
                      )}
                      {/* {Dynamic form of the any License Certificate} */}
                      {licenseFormType === 3 && (
                        <LicenseCertificateCommonForm
                          handleChange={handleChange}
                          values={values}
                          errors={errors}
                          touched={touched}
                          file={file}
                          data={data}
                          edit1={edit1}
                          setEdit1={setEdit1}
                          setFile={setFile}
                          edit2={edit2}
                          setEdit2={setEdit2}
                        />
                      )}
                    </Grid>

                    <Box className={`${styles.button} button-primary`}>
                      <Button type="submit" disabled={loading}>
                        {" "}
                        {loading ? (
                          <p style={{ color: "white" }}>Loading...</p>
                        ) : (
                          "Save"
                        )}
                      </Button>
                      <Button className="secondary-btn" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
