import React, { useEffect, useState, useCallback } from "react";
import Container from "./Container";
import { Box, Button, Grid } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import style from "./style.module.scss";

import PreviewForm from "./PreviewForm";
import { useDispatch, useSelector } from "react-redux";
import ConfirmFormSubmit from "../modal";
import { useLocation } from "react-router-dom";
import { storeFormStructure } from "../../../store/actions/storeFormStructure";
import { FORM_STRUCTURE_UPDATE } from "../../../store/constants/constants";
import { updateForm } from "../../../api/candidate/candidate.class";
import { draftSave } from "../../../api/candidate/candidate.class";
import { ShowAlert } from "../../../store/actions/alertActions";
import { useNavigate } from "react-router-dom";
import {TouchBackend} from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';

const NewFormBuilder = ({}) => {
  let dispatch = useDispatch();
  const location = useLocation();
  const edit_data = location?.state?.edit_data;
  const edit_uuid = location?.state?.edit_uuid;

  useEffect(() => {
    if (location.pathname === "/dashboard/create-form") {
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, []));
    } else {
      dispatch(storeFormStructure(FORM_STRUCTURE_UPDATE, edit_data));
    }
  }, [FORM_STRUCTURE_UPDATE]);

  const [showPreview, setShowPreview] = useState(false);
  const getpreviewform = useSelector((state) => state?.getpreviewform);
  const [confirmForm, setConfirmForm] = useState(false);
  const [confirmForm2, setConfirmForm2] = useState(false);
  const [draft, setDraft] = useState("0");
  const [apiDraft, setApiDraft] = useState();
  const [loader, setLoader] = useState(false);
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const handleSaveForm = () => {};

  const onSubmit = async (values) => {
    try {
      if (getpreviewform.length > 0) {
        setLoader(false);
        let payload = {
          form_builder_json: getpreviewform,
          workspace_id: auth?.results?.workspace_id,
          recruiter_id: auth?.results?.recruiter_id,
          uuid: edit_uuid,
          isDraft: 0,
        };
        const response = await updateForm({
          ...payload,
        });

        dispatch(ShowAlert(response?.data?.message, "success"));
        if (response.data.success) {
          setLoader(false);

          navigate("/dashboard/forms/all");
        }else{
          setLoader(false);
          // setSubmitting(false);
          if (typeof response.data.message === 'string' || response.data.message instanceof String){
            dispatch(ShowAlert(response?.data?.message, "error"));
          }else{
            const errorMessages = Object.values(response.data.message).flat();
            errorMessages.forEach((message) => {
              dispatch(ShowAlert(message, "error"));
            });
          }
        }
      } else {
        dispatch(ShowAlert("Form Data is Empty", "error"));
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateDraft = async (values) => {
    try {
      if (getpreviewform.length > 0) {
        setLoader(false);
        let payload = {
          form_builder_json: getpreviewform,
          workspace_id: auth?.results?.workspace_id,
          recruiter_id: auth?.results?.recruiter_id,
        };
        const response = await draftSave({
          ...payload,
          isDraft: 1,
          uuid: edit_uuid,
        });

        dispatch(ShowAlert(response?.data?.message, "success"));
        if (response.data.success) {
          setLoader(false);

          navigate("/dashboard/forms/all");
        }
      } else {
        dispatch(ShowAlert("Form Data is Empty", "error"));
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const data = location?.state?.data;
  const uuid = location?.state?.uuid;
  const isDraft = location?.state?.draft;

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={` ${style.buttonGroup}`}>
            <div className={` ${style.buttonGroup} mb-0`}>
              <div className={`secondary-btn ${style.button}`}>
                {showPreview === true ? (
                  <Button onClick={() => setShowPreview(!showPreview)}>
                    Back
                  </Button>
                ) : (
                  <Button onClick={() => setShowPreview(!showPreview)}>
                    Preview Form
                  </Button>
                )}
              </div>
              {showPreview === false ? (
                location.pathname === "/dashboard/create-form" ? (
                  <div className={` ${style.button}`}>
                    <Button onClick={() => setConfirmForm(true)}>
                      Save Form
                    </Button>
                  </div>
                ) : (
                  <div className={` ${style.button}`}>
                    <Button onClick={() => onSubmit()}>Update Form</Button>
                  </div>
                )
              ) : (
                ""
              )}

              {isDraft === "1" && showPreview === false ? (
                <div className={` ${style.button}`}>
                  <Button onClick={() => UpdateDraft()}>Update Draft</Button>
                </div>
              ) : (
                ""
              )}

              {location.pathname === "/dashboard/create-form" &&
              showPreview === false ? (
                <div className={` ${style.button}`}>
                  <Button onClick={() => setConfirmForm2(true)}>
                    Draft Form
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <Button className={style.secondaryBtn} onClick={() => ""}>
              Delete Form
            </Button> */}
          </div>
        </Grid>
        <Grid item xs={12}>
          {showPreview ? (
            <DndProvider>
              <PreviewForm
                getpreviewform={getpreviewform}
                showPreview={showPreview}
              />
            </DndProvider>
          ) : (
            <DndProvider backend={ isMobile ? TouchBackend : HTML5Backend} context={window}>
              <Container />
            </DndProvider>
          )}
        </Grid>
      </Grid>
      <ConfirmFormSubmit
        openModal={confirmForm}
        setOpenModal={setConfirmForm}
        formData={getpreviewform}
        uuid={uuid}
        draft={"0"}
        apiDraft={apiDraft}
      />
      <ConfirmFormSubmit
        openModal={confirmForm2}
        setOpenModal={setConfirmForm2}
        formData={getpreviewform}
        uuid={uuid}
        draft={"1"}
        apiDraft={apiDraft}
      />
    </>
  );
};

export default NewFormBuilder;
