import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useGetCombineDocMetadataForDocumentSigningFlow from "../../../customHook/useGetCombinedMetadataForDocumentSigningFlow";
import DocSigningPreviewDocPdf from "./DocSigningPreviewDocPdf.jsx";

function DocPreviewCombinedSignersPdf() {
  const { id } = useParams();
  const {
    docInfo,
    onPassSubmit,
    setDecPassword,
    isProgress,
    setShowPasswordPopup,
    docResult,
  } = useGetCombineDocMetadataForDocumentSigningFlow({ id, isPdfView: true });

  useEffect(()=>{
    setShowPasswordPopup(false);
    if(docResult && !docInfo){
      let docData = docResult?.payload?.doc?.password;
      setDecPassword(atob(docData));
      onPassSubmit();
    }
  }, [isProgress, docResult, docInfo]);
  return (
    <>
      {docInfo && (
        <div>
          <DocSigningPreviewDocPdf metadata={docInfo} title={docInfo.blob.title} />
        </div>
      )}
    </>
  );
}

export default DocPreviewCombinedSignersPdf;
