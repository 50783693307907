import EndPoints from "../EndPoints";
import axios from "../axiosConfig";
// Backycheck Verification

export const getBackycheckDetails = async ( id = null) => {
  try {
    let url = `${EndPoints.getBackycheckDetails}`;
    if(id){
      url = `${process.env.REACT_APP_API_URL}${EndPoints.getAdminBackycheckDetails}?id=${id}`;
      return await axios.get(url);
    }else{
      return await axios.get(url);
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getBackycheckPolicyForm = async (formtype, id = null) => {
  try {
    let url = `${EndPoints.getBackycheckPolicyForm}/${formtype}`;
    url += id ? `?id=${id}` : '';
    return await axios.get(url);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const updateBackycheckPolicyForm = async (payload, isAdmin = false) => {
  try {
    if(isAdmin){
      return await axios.post(`${EndPoints.updateAdminBackycheckPolicyForm}`, payload);
    }else{
      return await axios.post(`${EndPoints.updateBackycheckPolicyForm}`, payload);
    }
    
  } catch (error) {
    return { success: false, message: error.message };
  }
};