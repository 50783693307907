import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import LoadingBar from "react-top-loading-bar";

import DocSigningPreviewDoc from "./DocSigningPreviewDoc.jsx";
import AskPassword from "../../document_sinner/AskPassword.jsx";
// import DashboardHeader from '../common/DashboardHeader';
import useGetCombineDocMetadataForDocumentSigningFlow from "../../../customHook/useGetCombinedMetadataForDocumentSigningFlow";
// import PageNotFound from './PageNotFound';
import SpinnerLoader from "../../document_sinner/SpinnerLoader.jsx";
import { routes } from "../../../routes.js";

function DocPreviewCombinedSigners() {
  const ref = useRef();
  const { id } = useParams();
  const {
    docInfo,
    notfound,
    showPasswordPopup,
    onPassSubmit,
    passError,
    setDecPassword,
    isProgress,
    setShowPasswordPopup,
    docResult,
    decPassword,
    mainMeta,
  } = useGetCombineDocMetadataForDocumentSigningFlow({ id });
  console.log("docInfo", docInfo);

  const navigation = useNavigate();

  const hideAskPassword = () => {
    navigation(routes.ALL_DOCUMENTS);
    setShowPasswordPopup(false);
  };

  useEffect(()=>{    
    if(docResult && !docResult?.payload?.doc?.is_password_required){
      setDecPassword(process.env.REACT_APP_DEFAULT_PASSWORD)
      console.log("set Password");
      
    }
    // console.log('docResult && !docResult?.payload?.doc?.is_password_required && decPassword', docResult && !docResult?.payload?.doc?.is_password_required && decPassword, docResult, !docResult?.payload?.doc?.is_password_required, decPassword);
    
    if(docResult && !docResult?.payload?.doc?.is_password_required && decPassword && mainMeta){
      onPassSubmit()
       console.log("submit set password");
    }
  }, [docResult, decPassword, showPasswordPopup, mainMeta]);
  
  return (
    <>
      {/* <DashboardHeader /> */}
      <SpinnerLoader isOpen={isProgress} />

      <AskPassword
        decryptionPopup={showPasswordPopup}
        onPassSubmit={onPassSubmit}
        passError={passError}
        setDecPassword={setDecPassword}
        hideAskPassword={hideAskPassword}
      />
      {/* {notfound && <PageNotFound />} */}
      {docInfo && (
        <div>
          <DocSigningPreviewDoc metadata={docInfo} title={docInfo.blob.title} />
        </div>
      )}
    </>
  );
}

export default DocPreviewCombinedSigners;
