/* eslint-disable jsx-a11y/alt-text */
import Proptypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { FieldTypes } from "../../../store/constants/constants";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
import "../../../assets/sinner_css/DocSign.css";

GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

/**
 * this component used to preview pdf for all signers in signing flow
 * @param {*} param0
 * @returns
 */
function DocSigningPreviewDocPdf({ metadata, title }) {
  const canvasNewRef = useRef();
  // const { id } = useParams();
  const [arrayBuffer, setArrayBuffer] = useState();
  const [signerInfo, setSignerInfo] = useState([]);
  const [showAlreadySign, setShowAlreadySign] = useState(false);

  useEffect(() => {
    const getSignersInfo = async () => {
      const arrayTwoIds = new Set(metadata.signers.map((el) => el.email));
      const arrayOneFiltered = metadata.signers.filter((el) => !el.hasSigned);
      for (let i = 0; i < metadata?.signers?.length; i++) {
        if (metadata?.signers[i]?.hasSigned) {
          setShowAlreadySign(true);
          for (let j = 0; j < arrayOneFiltered?.length; j++) {
            if (metadata?.signers[i].email === arrayOneFiltered[j].email) {
              arrayOneFiltered.splice(j, 1);
            }
          }
        }
      }
      const uniqueEmails = new Set();
  
      // Use filter to create a new array without duplicate emails
      const filteredArray = arrayOneFiltered?.filter((obj) => {
        const email = obj.email;
  
        // If the email is not in the Set, add it and include the object in the filtered array
        if (!uniqueEmails.has(email)) {
          uniqueEmails.add(email);
          return true;
        }
  
        // If the email is already in the Set, exclude the object from the filtered array
        return false;
      });
      setSignerInfo(filteredArray);
    };

    if (metadata.blob) {
      setArrayBuffer(
        metadata.blob && metadata.blob.arrayBuffer
          ? metadata.blob.arrayBuffer
          : null
      );
    }
    getSignersInfo();
  }, [metadata]);

  function dataURLtoFile(dataurl, filename) {
    if(dataurl){
      const raw = atob(dataurl.split(",")[1]);
      const rawLength = raw.length;
  
      const array = new Uint8Array(new ArrayBuffer(rawLength));
      for (let i = 0; i < rawLength; i += 1) {
        array[i] = raw.charCodeAt(i);
      }
  
      return array;
    }
    return [];
  }

  const changeInNumber = (field) => {
    if (
      field?.height &&
      typeof field?.height === "string" &&
      field?.height?.includes("px")
    ) {
      return Number(
        field?.height.split("px")[0] || field?.height.split("PX")[0]
      );
    }
    return field?.height;
  };

  useEffect(()=>{

    const handleDownload = async () => {
      const pdfDoc = await PDFDocument.load(arrayBuffer);
  
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
      const pages = pdfDoc.getPages();
      let checkBoxIds = [];
      metadata.signers.forEach((element) => {
        element.fields.forEach(async (field) => {
          if (
            field.fieldType === FieldTypes.TEXT_INPUT ||
            field.fieldType === FieldTypes.DATES
          ) {
            if(field.textValue){
              pages[field?.page - 1].drawText(field.textValue, {
                x:
                  field.positionFromParentLeft -
                  (field.maxWidth - field.pageWidth) / 2,
                y: field.pagePositionY - changeInNumber(field) / 2,
                width: field.width,
                height: changeInNumber(field),
                size: 16,
                font: helveticaFont,
              });
            }
          }
  
          if (field.fieldType === FieldTypes.CHECKBOX && !checkBoxIds.includes(field.id)) {
            const form = pdfDoc.getForm();
            const checkBox = form.createCheckBox(
              `some.checkBox.checked${field.id}`
            );
  
            checkBox.addToPage(pages[field?.page - 1], {
              x:
                field.positionFromParentLeft -
                (field.maxWidth - field.pageWidth) / 2,
              y: field.pagePositionY - changeInNumber(field),
              width: field.width,
              height: changeInNumber(field),
              textColor: rgb(0, 0, 1),
              borderColor: rgb(0, 0, 1),
              borderWidth: 2,
            });
            checkBox.check();
            checkBox.enableReadOnly();
            checkBoxIds.push(field.id);
          }
  
          if (
            field.fieldType === FieldTypes.INITIALS ||
            field.fieldType === FieldTypes.SIGNATURE
          ) {
            if(field.base64Image){
              const data = dataURLtoFile(field.base64Image, "hello.png");
              const jpgImage = await pdfDoc.embedPng(data);
              pages[field?.page - 1].drawImage(jpgImage, {
                x:
                  field.positionFromParentLeft -
                  (field.maxWidth - field.pageWidth) / 2,
                y: field.pagePositionY - changeInNumber(field),
                width: field.width,
                height: changeInNumber(field),
              });
            }
          }
        });
      });
      let page = pdfDoc.addPage();
      const { width, height } = page.getSize();
      page.drawText("Signing Documents", {
        x: 210,
        y: height - 4 * 15,
        size: 20,
        font: helveticaFont,
      });
  
      page.drawText(`Document title: `, {
        x: 50,
        y: height - 9 * 16,
        size: 16,
      });
  
      page.drawText(`${metadata.blob.title}`, {
        x: 200,
        y: height - 9 * 16,
        size: 16,
        color: rgb(0.45, 0.45, 0.45),
      });
  
      page.drawText(`Shared By`, {
        x: 50,
        y: height - 11 * 16,
        size: 18,
      });
  
      page.drawText(`Name`, {
        x: 50,
        y: height - 13 * 16,
        size: 14,
      });
  
      page.drawText(`${metadata.blob.sender.name}`, {
        x: 200,
        y: height - 13 * 16,
        size: 14,
        color: rgb(0.45, 0.45, 0.45),
      });
  
      page.drawText(`Email`, {
        x: 50,
        y: height - 14 * 16,
        size: 14,
      });
  
      page.drawText(`${metadata.blob.sender.email}`, {
        x: 200,
        y: height - 14 * 16,
        size: 14,
        color: rgb(0.45, 0.45, 0.45),
      });
  
      let signedCodumentHeight = 16;
      let documentHeight;
  
      
      if (metadata && metadata.signers && metadata.signers.length) {
        if (showAlreadySign) {
          page.drawText("Already Signed documents", {
            x: 50,
            y: height - 16 * 16,
            size: 18,
          });
        }
        let alreadySigned = [];
        metadata.signers.map((item) => {
          if (item?.hasSigned && !alreadySigned.includes(item.email)) {
            signedCodumentHeight = signedCodumentHeight + 2;
  
            documentHeight = height - signedCodumentHeight * 16;
            if (documentHeight > height || documentHeight < 0) {
              page = pdfDoc.addPage();
              signedCodumentHeight = 4;
            }

            page.drawText("Name", {
              x: 50,
              y: height - signedCodumentHeight * 16,
              size: 14,
            });
            page.drawText(item.name, {
              x: 200,
              y: height - signedCodumentHeight * 16,
              size: 14,
              color: rgb(0.45, 0.45, 0.45),
            });
            signedCodumentHeight = signedCodumentHeight + 1;
  
            documentHeight = height - signedCodumentHeight * 16;
            if (documentHeight > height || documentHeight < 0) {
              page = pdfDoc.addPage();
              signedCodumentHeight = 4;
            }
  
            page.drawText("Email", {
              x: 50,
              y: height - signedCodumentHeight * 16,
              size: 14,
            });
            page.drawText(item.email, {
              x: 200,
              y: height - signedCodumentHeight * 16,
              size: 14,
              color: rgb(0.45, 0.45, 0.45),
            });
            alreadySigned.push(item.email);
          }
        });
  
        signedCodumentHeight = signedCodumentHeight + 2;
  
        documentHeight = height - signedCodumentHeight * 16;
        if (documentHeight > height || documentHeight < 0) {
          page = pdfDoc.addPage();
          signedCodumentHeight = 4;
        }
      }
      if (signerInfo && signerInfo.length) {
        page.drawText("Waiting For Signature", {
          x: 50,
          y: height - signedCodumentHeight * 16,
          size: 18,
        });
  
        signerInfo.map((item, key) => {
          signedCodumentHeight = signedCodumentHeight + 2;
  
          documentHeight = height - signedCodumentHeight * 16;
          if (documentHeight > height || documentHeight < 0) {
            page = pdfDoc.addPage();
            signedCodumentHeight = 4;
          }
  
          page.drawText("Name", {
            x: 50,
            y: height - signedCodumentHeight * 16,
            size: 14,
          });
          page.drawText(item.name, {
            x: 200,
            y: height - signedCodumentHeight * 16,
            size: 14,
            color: rgb(0.45, 0.45, 0.45),
          });
          signedCodumentHeight = signedCodumentHeight + 1;
  
          documentHeight = height - signedCodumentHeight * 16;
          if (documentHeight > height || documentHeight < 0) {
            page = pdfDoc.addPage();
            signedCodumentHeight = 4;
          }
  
          page.drawText("Email", {
            x: 50,
            y: height - signedCodumentHeight * 16,
            size: 14,
          });
          page.drawText(item.email, {
            x: 200,
            y: height - signedCodumentHeight * 16,
            size: 14,
            color: rgb(0.45, 0.45, 0.45),
          });
        });
      }
  
      const pdfBytes = await pdfDoc.save();
      renderPDFAsImages(pdfBytes);
    };

    // const handleDownload = async () => {
    //   const pdfDoc = await PDFDocument.load(arrayBuffer);
  
    //   const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
    //   const pages = pdfDoc.getPages();
    //   console.log("metadata.signers", metadata.signers);
  
    //   metadata.signers.forEach((element) => {
    //     element.fields.forEach(async (field) => {
    //       if (
    //         field.fieldType === FieldTypes.TEXT_INPUT ||
    //         field.fieldType === FieldTypes.DATES
    //       ) {
    //         console.log("field?.page", field?.page);
    //         pages[field?.page - 1].drawText(field.textValue, {
    //           x:
    //             field.positionFromParentLeft -
    //             (field.maxWidth - field.pageWidth) / 2,
    //           y: field.pagePositionY - changeInNumber(field) / 2,
    //           width: field.width,
    //           height: changeInNumber(field),
    //           size: 16,
    //           font: helveticaFont,
    //         });
    //       }
  
    //       if (field.fieldType === FieldTypes.CHECKBOX) {
    //         const form = pdfDoc.getForm();
    //         const checkBox = form.createCheckBox(
    //           `some.checkBox.checked${field.id}`
    //         );
    //         console.log("width", field.width);
  
    //         checkBox.addToPage(pages[field?.page - 1], {
    //           x:
    //             field.positionFromParentLeft -
    //             (field.maxWidth - field.pageWidth) / 2,
    //           y: field.pagePositionY - changeInNumber(field),
    //           width: field.width,
    //           height: changeInNumber(field),
    //           textColor: rgb(0, 0, 1),
    //           borderColor: rgb(0, 0, 1),
    //           borderWidth: 2,
    //         });
    //         checkBox.check();
    //         checkBox.enableReadOnly();
    //       }
  
    //       if (
    //         field.fieldType === FieldTypes.INITIALS ||
    //         field.fieldType === FieldTypes.SIGNATURE
    //       ) {
    //         console.log("field.base64Image", field.base64Image);
    //         const data = dataURLtoFile(field.base64Image, "hello.png");
    //         console.log(data);
    //         const jpgImage = await pdfDoc.embedPng(data);
    //         pages[field?.page - 1].drawImage(jpgImage, {
    //           x:
    //             field.positionFromParentLeft -
    //             (field.maxWidth - field.pageWidth) / 2,
    //           y: field.pagePositionY - changeInNumber(field),
    //           width: field.width,
    //           height: changeInNumber(field),
    //         });
    //       }
    //     });
    //   });
  
    //   console.log("pdfDoc", pdfDoc);
  
    //   let page = pdfDoc.addPage();
    //   const { width, height } = page.getSize();
    //   page.drawText("Signing Documents", {
    //     x: 210,
    //     y: height - 4 * 15,
    //     size: 20,
    //     font: helveticaFont,
    //   });
  
    //   page.drawText(`Document title: `, {
    //     x: 50,
    //     y: height - 9 * 16,
    //     size: 16,
    //   });
  
    //   page.drawText(`${metadata.blob.title}`, {
    //     x: 200,
    //     y: height - 9 * 16,
    //     size: 16,
    //     color: rgb(0.45, 0.45, 0.45),
    //   });
  
    //   page.drawText(`Shared By`, {
    //     x: 50,
    //     y: height - 11 * 16,
    //     size: 18,
    //   });
  
    //   page.drawText(`Name`, {
    //     x: 50,
    //     y: height - 13 * 16,
    //     size: 14,
    //   });
  
    //   page.drawText(`${metadata.blob.sender.name}`, {
    //     x: 200,
    //     y: height - 13 * 16,
    //     size: 14,
    //     color: rgb(0.45, 0.45, 0.45),
    //   });
  
    //   page.drawText(`Email`, {
    //     x: 50,
    //     y: height - 14 * 16,
    //     size: 14,
    //   });
  
    //   page.drawText(`${metadata.blob.sender.email}`, {
    //     x: 200,
    //     y: height - 14 * 16,
    //     size: 14,
    //     color: rgb(0.45, 0.45, 0.45),
    //   });
  
    //   let signedCodumentHeight = 16;
    //   let documentHeight;
  
    //   if (metadata && metadata.signers && metadata.signers.length) {
    //     if (showAlreadySign) {
    //       page.drawText("Already Signed documents", {
    //         x: 50,
    //         y: height - 16 * 16,
    //         size: 18,
    //       });
    //     }
    //     let signedEmails = [];
    //     metadata.signers.map((item) => {
    //       console.log("itemsss", item);
    //       if(!signedEmails.includes(item.email)){
    //         if (item?.hasSigned) {
    //           signedCodumentHeight = signedCodumentHeight + 2;
    
    //           documentHeight = height - signedCodumentHeight * 16;
    //           if (documentHeight > height || documentHeight < 0) {
    //             page = pdfDoc.addPage();
    //             signedCodumentHeight = 4;
    //           }
    
    //           page.drawText("Name", {
    //             x: 50,
    //             y: height - signedCodumentHeight * 16,
    //             size: 14,
    //           });
    //           page.drawText(item.name, {
    //             x: 200,
    //             y: height - signedCodumentHeight * 16,
    //             size: 14,
    //             color: rgb(0.45, 0.45, 0.45),
    //           });
    //           signedCodumentHeight = signedCodumentHeight + 1;
    
    //           documentHeight = height - signedCodumentHeight * 16;
    //           if (documentHeight > height || documentHeight < 0) {
    //             page = pdfDoc.addPage();
    //             signedCodumentHeight = 4;
    //           }
    
    //           page.drawText("Email", {
    //             x: 50,
    //             y: height - signedCodumentHeight * 16,
    //             size: 14,
    //           });
    //           page.drawText(item.email, {
    //             x: 200,
    //             y: height - signedCodumentHeight * 16,
    //             size: 14,
    //             color: rgb(0.45, 0.45, 0.45),
    //           });
    //         }
    //          signedEmails.push(item.email);
    //       }
    //     });
  
    //     signedCodumentHeight = signedCodumentHeight + 2;
  
    //     documentHeight = height - signedCodumentHeight * 16;
    //     if (documentHeight > height || documentHeight < 0) {
    //       page = pdfDoc.addPage();
    //       signedCodumentHeight = 4;
    //     }
    //   }
    //   console.log("signerInfo", signerInfo);
    //   if (signerInfo && signerInfo.length) {
    //     page.drawText("Waiting For Signature", {
    //       x: 50,
    //       y: height - signedCodumentHeight * 16,
    //       size: 18,
    //     });
  
    //     signerInfo.map((item, key) => {
    //       signedCodumentHeight = signedCodumentHeight + 2;
  
    //       documentHeight = height - signedCodumentHeight * 16;
    //       if (documentHeight > height || documentHeight < 0) {
    //         page = pdfDoc.addPage();
    //         signedCodumentHeight = 4;
    //       }
  
    //       page.drawText("Name", {
    //         x: 50,
    //         y: height - signedCodumentHeight * 16,
    //         size: 14,
    //       });
    //       page.drawText(item.name, {
    //         x: 200,
    //         y: height - signedCodumentHeight * 16,
    //         size: 14,
    //         color: rgb(0.45, 0.45, 0.45),
    //       });
    //       signedCodumentHeight = signedCodumentHeight + 1;
  
    //       documentHeight = height - signedCodumentHeight * 16;
    //       if (documentHeight > height || documentHeight < 0) {
    //         page = pdfDoc.addPage();
    //         signedCodumentHeight = 4;
    //       }
  
    //       page.drawText("Email", {
    //         x: 50,
    //         y: height - signedCodumentHeight * 16,
    //         size: 14,
    //       });
    //       page.drawText(item.email, {
    //         x: 200,
    //         y: height - signedCodumentHeight * 16,
    //         size: 14,
    //         color: rgb(0.45, 0.45, 0.45),
    //       });
    //     });
    //   }
  
    //   const pdfBytes = await pdfDoc.save();
    //   renderPDFAsImages(pdfBytes);
    // };
    const A4_WIDTH = 595; // A4 width in points
    const A4_HEIGHT = 842; // A4 height in points
    
    const renderPDFAsImages = async (pdfBytes) => {
      const pdfData = new Uint8Array(pdfBytes);
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
    
      const loadingTask = getDocument(url);
      loadingTask.promise.then(async (pdf) => {
        const numPages = pdf.numPages; // Get the total number of pages
    
        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const scale = 1; // Adjust the scale as needed
          const viewport = page.getViewport({ scale });
          // Create a canvas for each page
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = A4_HEIGHT;
          canvas.width = A4_WIDTH;
          // canvas.style.border = '2px solid black';
          canvas.style.margin = '0 15px';
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          await page.render(renderContext).promise;
    
          canvasNewRef.current.appendChild(canvas);
        }
    
        // Clean up the object URL
        URL.revokeObjectURL(url);
      }).catch((error) => {
        console.error('Error rendering PDF:', error);
      });
    };
    if(arrayBuffer){
      handleDownload()
    }
  }, [arrayBuffer, metadata, showAlreadySign, signerInfo]);

  return (
      <div>
        <div ref={canvasNewRef} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} />
      </div>
  );
}
DocSigningPreviewDocPdf.protoTypes = {
  metadata: Proptypes.any,
};
export default DocSigningPreviewDocPdf;
