import React, { useEffect, useRef, useState } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist/build/pdf";
import { downloadFile } from "../../utils/s3Config";
import { FieldTypes } from "../../store/constants/constants";
import "../../assets/sinner_css/DocSign.css";
import "../../assets/sinner_css/assignFields.css";
import { Container } from "react-bootstrap";
import { Box, CircularProgress } from "@mui/material";
import ReceiverInput from "../../components/document_sinner/backycheck_input/ReceiverInput";
import ReceiverSignature from "../../components/document_sinner/backycheck_input/ReceiverSignature";
import ReceiverDate from "../../components/document_sinner/backycheck_input/ReceiverDate";
import ReceiverCheckbox from "../../components/document_sinner/backycheck_input/ReceiverCheckbox";
import ReceiverInitials from "../../components/document_sinner/backycheck_input/ReceiverInitials";
GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

const AdminPDFView = ({
  formInputs,
  setFormInputs,
  preFillData,
  isFormFilled,
  backyCheckDetails
}) => {  
  const pdfBgRef = useRef();
  const [pageImages, setPageImages] = useState([]);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [pdfScale, setPdfScale] = useState(1);

  useEffect(() => {
    const renderPDF = async () => {
      if (backyCheckDetails && pageImages.length === 0) {
        const url = backyCheckDetails.npcs_pdf_url;
        const pdfUrl = downloadFile(url);
        const loadingTask = getDocument(pdfUrl);
        
        try {
          const pdf = await loadingTask.promise;
          const images = []; // Local array to hold Base64 images
          let pageWidth = 0;
          let pageHeight = 0;
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const scale = window.innerWidth < 600 ? 0.6 : 1.5;
            setPdfScale(scale);
            const viewport = page.getViewport({ scale });
            pageHeight += viewport.height;
            pageWidth = pageWidth < viewport.width ? viewport.width : pageWidth;
            // Create a canvas for each page
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render the page into the canvas context
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext).promise;

            // Convert the canvas to a Base64 string
            const base64Image = canvas.toDataURL();
            images.push(base64Image); // Add to local array
          }
          setCanvasSize({ width: `${pageWidth}px`, height: `${pageHeight}px` });
          setPageImages(images); // Update state with Base64 images          
        } catch (error) {
          console.error("Error loading PDF:", error);
        }
      }
    };
    renderPDF();

    if (backyCheckDetails && backyCheckDetails.npcs_data) {
      const dataPDF = JSON.parse(backyCheckDetails.npcs_data);
      setFormInputs(dataPDF);
    }

    const handleResize = () => {
      renderPDF();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [backyCheckDetails, pageImages]);
  
  const updateField = (arrayIndex, fieldIndex, updates) => {
    setFormInputs((prevData) =>
      prevData.map((item, idx) => {
        if (idx === arrayIndex) {
          return {
            ...item,
            fields: item.fields.map((field, fIdx) => {
              if (fIdx === fieldIndex) return { ...field, ...updates };
              return field;
            }),
          };
        }
        return item;
      })
    );
  };
  return (
    <Container>
      {pageImages && pageImages.length === 0 && (
        <div style={{ height: "50vh", width: "100%" }}>
          <Box className="loader"></Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              alignItems: "center",
              gap: "0.8rem",
            }}
          >
            <CircularProgress />
            <span>Loading ...</span>
          </div>
        </div>
      )}
      <div className="canvasContainer">
        <div
          className="pdfWrapper"
          style={{ border: "1px solid", width: "fit-content", margin: "auto" }}
        >
          <div
            className="canvas"
            style={{
              position: "relative",
              height: canvasSize.height,
              width: canvasSize.width,
            }}
          >
            {pageImages.length > 0 && (
              <>
                <div className="pdfBg" ref={pdfBgRef} style={{ width: "100%" }}>
                  {pageImages.map((imgBase64, i) => {
                    return (
                      <img
                        alt="pdf"
                        key={i}
                        src={imgBase64}
                        className="pdfImage"
                        style={{
                          pointerEvents: "none",
                          borderBottom: "solid 1px",
                          maxWidth: "none",
                          width: "100%",
                        }}
                      ></img>
                    );
                  })}
                </div>
                {!isFormFilled &&
                  formInputs.map((signer, signerIndex) => {
                    if (!signer.isRecruiter) {
                      return signer.fields.map((field, fieldIndex) => {
                        field.positionFromParentLeft =
                          field.positionFromParentLeft < 0
                            ? 0
                            : field.positionFromParentLeft;
                        switch (field.fieldType) {
                          case FieldTypes.TEXT_INPUT:
                            return (
                              <ReceiverInput
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.CHECKBOX:
                            return (
                              <ReceiverCheckbox
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.INITIALS:
                            return (
                              <ReceiverInitials
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.DATES:
                            return (
                              <ReceiverDate
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.SIGNATURE:
                            return (
                              <ReceiverSignature
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                pdfScale={pdfScale}
                              />
                            );
                          default:
                            return <></>;
                        }
                      });
                    }
                    return null;
                  })}

                {isFormFilled &&
                  preFillData.map((signer, signerIndex) => {
                    if (!signer.isRecruiter) {
                      return signer.fields.map((field, fieldIndex) => {
                        field.positionFromParentLeft =
                          field.positionFromParentLeft < 0
                            ? 0
                            : field.positionFromParentLeft;
                        switch (field.fieldType) {
                          case FieldTypes.TEXT_INPUT:
                            return (
                              <ReceiverInput
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                isFilledForm={true}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.CHECKBOX:
                            return (
                              <ReceiverCheckbox
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                isFilledForm={true}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.INITIALS:
                            return (
                              <ReceiverInitials
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                isFilledForm={true}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.DATES:
                            return (
                              <ReceiverDate
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                isFilledForm={true}
                                pdfScale={pdfScale}
                              />
                            );
                          case FieldTypes.SIGNATURE:
                            return (
                              <ReceiverSignature
                                field={field}
                                key={field.id}
                                signerIndex={signerIndex}
                                fieldIndex={fieldIndex}
                                updateField={updateField}
                                isFilledForm={true}
                                pdfScale={pdfScale}
                              />
                            );
                          default:
                            return <></>;
                        }
                      });
                    }
                    return null;
                  })}
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AdminPDFView;
