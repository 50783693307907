import React, { useState } from "react";

/****
 * input date field component in pdf editing page.
 */

function ReceiverDate({ field, signerIndex, fieldIndex, updateField, pdfScale=1, isFilledForm=false}) {
  const [isFilled, setFilled] = useState(true);

  const handleTextInput = (e) => {
    setFilled(false);

    if (e.target.value.length === 0) {
      setFilled(true);
    }

    let filedData = {
      isFilled: false,
      textValue: ''
    }
    if (e.target.value.length > 0) {
      field.isFilled = true;
      field.textValue = e.target.value;
      filedData.isFilled = true;
      filedData.textValue = e.target.value;
    } else {
      field.isFilled = false;
      field.textValue = e.target.value;
      filedData.isFilled = false;
      filedData.textValue = e.target.value;
    }
    updateField(signerIndex, fieldIndex, filedData);

  };
  const getHeight = (height) => {
    let numberValue = height;
    if (typeof height === 'string' && height.endsWith('px')) {
      numberValue = parseInt(height.replace('px', ''), 10);
    }
    return numberValue * pdfScale;
  }
  return (
    <div
      style={{
        position: "absolute",
        left: field.positionFromParentLeft * pdfScale,
        top: field.positionFromParentTop * pdfScale,
        height: getHeight(field.height),
        width: field.width * pdfScale,
        // border: "1px solid green",
        fontFamily: field.fontFamily,
      }}>
      {!isFilledForm && (<input
        onChange={handleTextInput}
        placeholder={field.placeholder}
        style={{
          width: "100%",
          height: "100%",
          fontSize: field.fontSize,
          border: `1px solid ${field.errorMessage && isFilled ? "red" : "#767676"}`,
        }}
        type='date'
      />)}
      {isFilledForm && (
        <div style={{fontSize: `${field.fontSize * pdfScale}px`, fontFamily: 'Arial MT, Arial, sans-serif', width: '100%', height: '100%', display: 'flex', alignItems: 'center'}}>
          <div>
            {field.textValue}
          </div>
        </div>
      )}
    </div>
  );
}

export default ReceiverDate;
