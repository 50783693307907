import React, { useState } from "react";
// import '../../assets/css/Tooltip.css';

/****
 *  input component in pdf editing page.
 */

function ReceiverInput({ field , signerIndex, fieldIndex, updateField, isFilledForm=false, pdfScale=1}) {
  const [error, setError] = useState(false);
  const [isFilled, setFilled] = useState(true);
  

  const handleTextInput = (e) => {
    setFilled(false);

    if (e.target.value.length === 0) {
      setFilled(true);
    }
    let filedData = {
      isFilled: false,
      textValue: '',
    };

    let errorFlag = false;
    if (e.target.value.length > 0) {
      field.isFilled = true;
      field.textValue = e.target.value;
      filedData = {
        isFilled: true,
        textValue: e.target.value,
      };
      errorFlag = false;
    } else {
      field.isFilled = false;
      field.textValue = e.target.value;
      filedData = {
        isFilled: false,
        textValue: e.target.value,
      };
      errorFlag = true;
    }
    updateField(signerIndex, fieldIndex, filedData);
    setError(errorFlag);
  };

  return (
    <div
      style={{
        position: "absolute",
        left: field.positionFromParentLeft * pdfScale,
        top: field.positionFromParentTop * pdfScale,
        height: field.height * pdfScale,
        width: field.width * pdfScale,
        // border: "1px solid green",
        fontFamily: field.fontFamily,
      }}>
      {!isFilledForm && (
        <input
        onChange={handleTextInput}
        placeholder={field.placeholder}
        style={{
          width: "100%",
          height: "100%",
          fontSize: field.fontSize,
          border: `1px solid ${field.errorMessage && isFilled ? "red" : "#767676"}`,
        }}
        type='text'
      />
      )}
      {isFilledForm && (
        <div style={{fontSize: `${field.fontSize * pdfScale}px`, fontFamily: 'Arial MT, Arial, sans-serif'}}>
          {field.textValue}
        </div>
      )}
    </div>
  );
}

export default ReceiverInput;
