import EndPoints from "../EndPoints";
import axios from "../axiosConfig";
import axiosForm from "../uploadFile/axiosFormData";

export const notification = async (page) => {
    try {
      return await axios.post(EndPoints.notification+ '?page='+ page);
    } catch (error) {
      return { success: false, message: error.message };
    }
  };

export  const readNotification = async(e) =>{
    try{
      let response = await axios.post(EndPoints.notification + '/'+ e.uuid + '/mark-as-read')
    }
    catch (error) {
      console.error(error)
    }
}

export  const notificationpagination = async(page) =>{
  try{
    return await axios.post(EndPoints.notification )
  }
  catch (error) {
    return { success: false, message: error.message };
  }
}
export const readAllNotification = async() => {
  try{
    let response = await axios.post(EndPoints.notification + '/mark-as-all-read')
  }
  catch (error) {
    console.error(error)
  }
}
