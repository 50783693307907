/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import { Box, Typography, Button } from "@mui/material";
import CustomSelect from "../../common/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  createSingleCertificateFunc,
  resetSingleCandidateReff,
  updateSingleCertificateFunc,
} from "../../../store/actions/singleCandidateScreenActions";
import { useEffect } from "react";
import { getAllRecruiterCreatedIndustry } from "../../../store/actions/recruiterCandidateActions";
import CustomModel from "../model";
import CheckoutForm from "../checkout/checkout";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";

const InterviewPopup = ({
  open,
  setOpen,
  backgroundCheck,
  setBackgroundCheck,
  backyCheckDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const candidate = useSelector((state) => state?.rcandidate);
  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const [open2, setOpen2] = useState(false);
  const [backycheckInfoModel, setBackycheckInfoModel] = useState(false);
  const [backycheckPopupContant, setBackycheckPopupContant] = useState({
    heading: "",
    description: "",
    isPoliceCheck: false,
  });
  const [data, setData] = useState({});
  const [updatedData, setUpdatedData] = useState({});
  const [typedValue, setTypedValue] = useState("");

  // console.log(
  //   "candidate?.createdIndustry?.results",
  //   candidate?.createdIndustry?.results
  // );
  const backycheckService = ["nationally_coordinated_criminal_check", "credit_history_report_check_request", "working_with_children_check"];

  const optionsCreateIndustry = candidate?.createdIndustry?.results?.map(
    (parent) =>
      // console.log("parent", parent),
      ({
        label: parent?.name,

        options: parent?.industry_certification_license?.map((e) => {
          return {
            label: backgroundCheck ? `${e?.name} \t ($${e?.price})` : e.name,
            value: e.id,
            parent: parent.id,
            is_backy_check: backycheckService.includes(e.slug),
            slug: e?.slug
          };
        }),

        price: parent?.industry_certification_license?.map((e) => {
          return {
            label: e?.name,
            value: e.id,
            parent: parent.id,
            price: e?.price,
            is_backy_check: backycheckService.includes(e.slug),
            slug: e?.slug
          };
        }),
      })
  );

  const [values, setValues] = useState({
    createdIndustry:
      {
        label:
          singleCandidate?.editCertificate?.results
            ?.industry_certification_license?.name,
        value:
          singleCandidate?.editCertificate?.results
            ?.industry_certification_license?.id,
        parent:
          singleCandidate?.editCertificate?.results?.recruiter_industry?.id,
        price:
          singleCandidate?.editCertificate?.results
            ?.industry_certification_license?.price,
      } || "",
    new_industry_text: {
      label: "",
    },
  });

  const [Customerror, setErrors] = useState({});
  const validate = (data) => {
    const newError = {};
    for (let key in data) {
      let value = data[key];
      switch (key) {
        case "createdIndustry":
          if (value.length === 0 || value.toString() === "")
            newError[key] = "fields is Required";
          break;
        default:
      }
    }
    return newError;
  };

  const handleInputChange = (inputValue) => {
    setTypedValue(inputValue);
    if (inputValue) {
      // Update the values.createdIndustry state with the typed value
      setValues((prevValues) => ({
        ...prevValues,
        createdIndustry: {
          label: inputValue,
          value: null,
          parent: null,
          price: null,
        },
        new_industry_text: {
          label: inputValue,
        },
      }));
    }
  };
  // console.log("typedValue", typedValue);
  // console.log("values", values);

  const submitData = async (e) => {
    e.preventDefault();
    const validateSelect = validate(values);
    if (Object?.keys(validateSelect)?.length > 0) {
      setErrors(validateSelect);
      return;
    }

    if(backgroundCheck){
      if((!backyCheckDetails?.client_id || backyCheckDetails?.client_id === '' || backyCheckDetails?.is_npcs_active || !backyCheckDetails?.is_npcs_verified || backyCheckDetails?.is_amici_active || !backyCheckDetails?.is_amici_verified) && values?.createdIndustry?.slug === "nationally_coordinated_criminal_check"){
        setBackycheckPopupContant({
          heading: "Background Check Acceptance Of Terms Of Use",
          description: "As part of our agreement with our third party supplier of this check, we must register all customers by completing new customer form they have provided. Also required is a customer form which is to be registered with ACIC. Please review and fill in the forms required which will be sent to the third party for approval. This may take 3-5 business days for the approval.",
          isPoliceCheck: true,
        });
        setBackycheckInfoModel(true);
        return;
      }
  
      if((!backyCheckDetails?.client_id || backyCheckDetails?.client_id === '' || backyCheckDetails?.is_amici_active || !backyCheckDetails?.is_amici_verified) && values?.createdIndustry?.slug !== "nationally_coordinated_criminal_check"){
        setBackycheckPopupContant({
          heading: "Background Check Acceptance Of Terms Of Use",
          description: "As part of our agreement with our third party supplier of this check, we must register all customers by completing new customer form they have provided. Also required is a customer form which is to be registered with ACIC. Please review and fill in the forms required which will be sent to the third party for approval. This may take 3-5 business days for the approval.",
          isPoliceCheck: false,
        });
        setBackycheckInfoModel(true);
        return;
      }
    }    
    const local_data = {
      recruitment_industries: {
        recruitment_industry_1: {
          rec_ind_request_id: values?.createdIndustry?.parent?.toString() || "",
          ind_cert_lic_request_id:
            values?.createdIndustry?.value?.toString() || "",
        },
      },
      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: candidate?.showCandidate?.results?.id,
      request_type: "recruitment",
      new_industry_text: values?.createdIndustry?.value
        ? ""
        : values?.new_industry_text?.label,
      is_background_check: backgroundCheck
    };

    const local_data2 = {
      uuid: singleCandidate?.editCertificate?.results?.uuid,
      candidate_lic_cert_id:
        singleCandidate?.editCertificate?.results?.candidate_license?.id,
      recruitment_industries: {
        [singleCandidate?.editCertificate?.results?.id]: {
          rec_ind_request_id: values?.createdIndustry?.parent?.toString() || "",
          ind_cert_lic_request_id:
            values?.createdIndustry?.value?.toString() || "",
        },
      },
      recruiter_id: auth?.results?.recruiter_id,
      workspace_id: auth?.results?.workspace_id,
      candidate_id: candidate?.showCandidate?.results?.id,
      request_type: "recruitment",
    };

    // if (backgroundCheck === false) {
    if (singleCandidate?.editCertificate?.success === true) {
      dispatch(
        updateSingleCertificateFunc(local_data2, {
          workspace_id: auth?.results?.workspace_id,
          candidate_id: candidate?.showCandidate?.results?.id,
        })
      );
    } else {
      dispatch(
        createSingleCertificateFunc(local_data, {
          workspace_id: auth?.results?.workspace_id,
          candidate_id: candidate?.showCandidate?.results?.id,
        })
      );
    }
    // }

    // setValues("");
    // if (backgroundCheck === true)
    //   return setOpen2(true), setData(local_data), setUpdatedData(local_data2);
  };

  useEffect(() => {
    if (singleCandidate?.createCertificate?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.createCertificate]);
  useEffect(() => {
    if (singleCandidate?.updateCertificate?.success === true) {
      setOpen(false);
      dispatch(resetSingleCandidateReff());
    }
  }, [singleCandidate?.updateCertificate]);

  // useEffect(() => {
  //   return () => {
  //     console.log("return");
  //   };
  // }, []);

  return (
    <>
      <Box className="interviewParrent">
        <Typography variant="h4">
          Request for{" "}
          {backgroundCheck === true ? "Background Checks" : "License Copy"}{" "}
        </Typography>
        <form onSubmit={submitData} style={{width: "100%"}}>
          <Box>
            <CustomSelect
              options={optionsCreateIndustry}
              customClass={"interviewSelect"}
              label={
                <>
                  {backgroundCheck === true
                    ? "Select Checks"
                    : "Select License Type"}
                  <span
                    style={{
                      color: "red",
                      marginLeft: "3px",
                      position: "relative",
                      display: "initial",
                    }}
                  >
                    *
                  </span>
                </>
              }
              onFocus={() => {
                dispatch(
                  backgroundCheck === true
                    ? getAllRecruiterCreatedIndustry({
                        workspace_id: auth?.results?.workspace_id?.toString(),
                        recruiter_id: auth?.results?.recruiter_id.toString(),
                        industry_id:
                          auth?.results?.company_detail?.industry?.id,
                        automated_list: 1,
                        is_background_check: 1,
                      })
                    : getAllRecruiterCreatedIndustry({
                        workspace_id: auth?.results?.workspace_id?.toString(),
                        recruiter_id: auth?.results?.recruiter_id.toString(),
                        industry_id:
                          auth?.results?.company_detail?.industry?.id,
                      })
                );
              }}
              onChange={(e) => {
                setValues({
                  ...values,
                  createdIndustry: { ...e, parent: e?.parent },
                });

                if (Customerror.hasOwnProperty("createdIndustry")) {
                  delete Customerror["createdIndustry"];
                  setErrors(Customerror);
                }
              }}
              onInputChange={backgroundCheck === true ? "" : handleInputChange}
              // inputValue={typedValue}
              onMenuClose={() => {
                // Handle the menu close event
                if (!typedValue) {
                  // If the typedValue is empty, update the state to maintain the selected value
                  setValues((prevValues) => ({
                    ...prevValues,
                    new_industry_text: {
                      label: prevValues.createdIndustry?.label || "",
                    },
                  }));
                }
              }}
              value={
                typedValue
                  ? {
                      label: typedValue,
                      value: null,
                      parent: null,
                      price: null,
                    }
                  : values?.createdIndustry
              }
            />
            {Customerror?.createdIndustry && (
              <span>{Customerror?.createdIndustry}</span>
            )}
          </Box>

          <Box className="button-primary buttoninterview" style={{justifyContent: "center", gap: "2rem"}}>
            {/* <Button onClick={submitData} disabled={singleCandidate?.loading}>
              {singleCandidate?.loading ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : Object.keys(singleCandidate?.editCertificate)?.length > 0 ||
                backgroundCheck === false
                 ? (
                "Send"
              ) : (
                "Make Payment"
              )}
            </Button> */}
            <Button onClick={submitData} disabled={singleCandidate?.loading}>
              {singleCandidate?.loading ? (
                <p style={{ color: "white" }}>Loading...</p>
              ) : (
                "Send"
              )}
            </Button>
            <Button
              className="secondary-btn"
              onClick={() => {
                setOpen(false);
                dispatch(resetSingleCandidateReff());
              }}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
      <CustomModel
        open={open2}
        setOpen={setOpen2}
        data={data}
        setData={setData}
      >
        <CheckoutForm
          open={open2}
          setOpen={setOpen2}
          data={data}
          setData={setData}
          updatedData={updatedData}
        />
      </CustomModel>
      <CustomModel
        open={backycheckInfoModel}
        setOpen={setBackycheckInfoModel}
      >
        {console.log("backycheckPopupContant", backycheckPopupContant)}
        <div className={`backycheck_popup`}>
          <div className={`heading mb-3`}>
          <Typography variant="h5">
            {backycheckPopupContant.heading}
          </Typography>
          </div>
          <div className={`description`} style={{textAlign: 'justify'}}>
            <p>{backycheckPopupContant.description} </p>
            <p>Have a link to the form in the pop up so the customer can complete the form.</p>
            <p><span style={{fontWeight: 600}}>Forms required:</span></p>
            <ol style={{marginLeft: '1rem', color: '#757575'}}>
              <li><a href={`${routes.BACKYCHECK_URL.AMICI_URL}?id=${candidate?.showCandidate?.results?.uuid}`} target={'_blank'} rel={'noreferrer'} style={{color: '#00CFC5', fontWeight: 500}}>AMICI TECH CUSTOMER AGREEMENT</a> {backyCheckDetails?.is_amici_fill ? ` : ${backyCheckDetails?.is_amici_verified ? "Verified" : "In Review"}` : "Pending"}</li>
              {backycheckPopupContant.isPoliceCheck && (<li><a href={`${routes.BACKYCHECK_URL.NPCS_URL}?id=${candidate?.showCandidate?.results?.uuid}`} target={'_blank'} rel={'noreferrer'} style={{color: '#00CFC5', fontWeight: 500}}>NPCS CUSTOMER TERMS OF USE</a> {backyCheckDetails?.is_npcs_fill ? ` : ${backyCheckDetails?.is_npcs_verified ? "Verified" : "In Review"}` : " : Pending"}</li>)}
            </ol>
          </div>
          <div className="button-primary text-end w-100 d-flex align-items-end flex-column-reverse">
            <Button
                className="danger-btn" 
                onClick={() => {
                  setBackycheckInfoModel(false);
                  setBackycheckPopupContant({
                    heading: "",
                    description: "",
                    isPoliceCheck: false,
                  })
                }}
              >
                Cancel
              </Button>
          </div>
        </div>
      </CustomModel>
    </>
  );
};

export default InterviewPopup;
