import React, { useRef } from "react";
import { useParams } from "react-router-dom";

import { FieldTypes } from "../../store/constants/constants";
import ReceiverCheckbox from "./ReceiverCheckbox.jsx";
import ReceiverDate from "./ReceiverDate.jsx";
import ReceiverInitials from "./ReceiverInitials.jsx";
import ReceiverInput from "./ReceiverInput.jsx";
import ReceiverSignature from "./ReceiverSignature.jsx";
import "../../assets/sinner_css/assignFields.css";
/*****
 *in doc signing flow, this component renders the fields into pdf
 */

function SignDocContainer({ receiverMetadata }) {
  let state = useParams();
  const pdfBgRef = useRef();
  let user = JSON.parse(localStorage.getItem("auth"))?.results || {};
  if (state?.email) {
    user = { email: state?.email };
  }

  return (
    <div className="canvasContainer">
      <div
        className="pdfWrapper"
        style={{
          border: "1px solid",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <div
          className="canvas"
          style={{
            position: "relative",
            height: receiverMetadata?.containerSize?.height,
            width: receiverMetadata?.containerSize?.width,
          }}
          // ref={canvasContainerRef}
        >
          <div className="pdfBg" ref={pdfBgRef}>
            {receiverMetadata.pdfImagesList.map((m, i) => (
              <img
                alt="pdf"
                key={i}
                src={m.imgBase64}
                className="pdfImage"
                style={{
                  pointerEvents: "none",
                  borderBottom: "solid 1px",
                  maxWidth: "none",
                }}
              ></img>
            ))}
          </div>

          {receiverMetadata.signers.map((signer) => {
            return signer.fields.map((field, i) => {
              field.positionFromParentLeft =
                field.positionFromParentLeft < 0
                  ? 0
                  : field.positionFromParentLeft;

              if (user.email.toLowerCase() !== signer.email.toLowerCase())
                return <></>;

              switch (field.fieldType) {
                case FieldTypes.TEXT_INPUT:
                  return <ReceiverInput field={field} key={field.id} />;
                case FieldTypes.CHECKBOX:
                  return <ReceiverCheckbox field={field} key={field.id} />;
                case FieldTypes.INITIALS:
                  return <ReceiverInitials field={field} key={field.id} />;
                case FieldTypes.DATES:
                  return <ReceiverDate field={field} key={field.id} />;
                case FieldTypes.SIGNATURE:
                  return <ReceiverSignature field={field} key={field.id} />;
                default:
                  return <></>;
              }
            });
          })}
        </div>
      </div>
    </div>
  );
}

export default SignDocContainer;
