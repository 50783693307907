import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReceiverMetadata } from "../../../store/actions/receiverMetadata.action";
import InitialsPopupModal from "../InitialsPopupModal";

/****
 * receiver initials field for signing flow
 */

function ReceiverInitials({ field, signerIndex, fieldIndex, updateField, isFilledForm = false, pdfScale = 1 }) {
  const [openSignatureBox, setOpenSignatureBox] = useState(false);
  const dispatch = useDispatch();
  const receiverMetadata = useSelector(
    (state) => state.receiverMetadataReducer
  );

  const [isFilled, setIsFilled] = useState(true);

  const onSignatureDone = (field, data) => {
    console.log("55");
    setIsFilled(false);

    if (data === "") {
      setIsFilled(true);
    }

    let filedData = {
      isFilled: false,
      base64Image: '',
    };

    if (data !== "") {
      field.isFilled = true;
      field.base64Image = data;
      filedData.isFilled = true;
      filedData.base64Image = data;
    } else {
      field.isFilled = false;
      field.base64Image = null;
      filedData.isFilled = false;
      filedData.base64Image = null;
    }
    updateField(signerIndex, fieldIndex, filedData);
    setOpenSignatureBox(false);
  };

  return (
    <div>
      <div
        onClick={() =>
          {
            if(!isFilledForm){
              setOpenSignatureBox(true)
            }
          } 
        }
        style={{
          position: "absolute",
          left: field.positionFromParentLeft * pdfScale,
          top: field.positionFromParentTop * pdfScale,
          height: field.height * pdfScale,
          width: field.width * pdfScale,
          // border: "1px solid green",
          border: "1px solid rgb(118, 118, 118)",
          background: "white",
          cursor: "pointer",
        }}
        title={field.isRequired && "Required"}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            border: `1px solid ${field.errorMessage && isFilled ? "red" : "#767676"}`,
          }}>
          {field.base64Image ? (
            <img
              src={field.base64Image}
              style={{ width: "100%", height: "100%" }}
              alt=''
            />
          ) : (
            "Initials"
          )}
        </span>
      </div>

      <InitialsPopupModal
        field={field}
        onSignatureDone={onSignatureDone}
        openSignatureBox={openSignatureBox}
        setOpenSignatureBox={setOpenSignatureBox}
      />
    </div>
  );
}

export default ReceiverInitials;
