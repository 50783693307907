import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import styles from "./styles.module.scss";
import { ReactComponent as Edit } from "../../../assets/candidates/edit.svg";
import { ReactComponent as Dustin } from "../../../assets/workspace/dustbin.svg";
import { ReactComponent as Eye } from "../../../assets/candidates/eye.svg";
import { ReactComponent as Archive } from "../../../assets/candidates/archive.svg";
import { Link, useNavigate } from "react-router-dom";
import DeleteForm from "../modal/DeleteForm";
import Switch from "@mui/material/Switch";
import {
  getDeactivateForms,
  statusForm,
  ArchivedForms,
} from "../../../api/candidate/candidate.class";
import EmailForm from "../modal/EmailForm";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../store/actions/alertActions";
import { routes } from "../../../routes";
import DeleteEDoc from "../modal/DeleteEDoc";
import {
  UpdateDocumentStatus,
  deleteTemplate,
} from "../../../api/sinner/SinnerApi";
import Modal from "react-responsive-modal";
import {
  LOADING_FALSE,
  LOADING_TRUE,
} from "../../../store/constants/constants";
import { setSigningMetadata } from "../../../store/actions/signingMetadata.actions";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: "#00CFC5", // this is working
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#00CFC5",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          // opacity: 0.2,
          backgroundColor: "#000000",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            // opacity: 0.7,
            backgroundColor: "#000000",
          },
        },
      },
    },
  },
});

const EDocTable = ({
  titles,
  rows,
  interview,
  setUpdate,
  type,
  page,
  setPage,
  total,
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [isDeleteClick, setIsDeleteClick] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successOpenModel, setSuccessOpenModel] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const singleCandidate = useSelector((state) => state?.singleCandidate);
  const userData = JSON.parse(localStorage.getItem("auth"))?.results || {};

  const handleStatus = async (status, uuid) => {
    try {
      setSuccessOpenModel(true);
      dispatch({
        type: LOADING_TRUE,
      });
      const query = `recruiter_id=${
        userData?.recruiter_id || ""
      }&candidate_id=${userData?.candidate_id || ""}&uuid=${
        uuid || ""
      }&doc_builder_status=${status + "" || ""}&workspace_id=${
        userData?.workspace_id || ""
      }`;
      const response = await UpdateDocumentStatus(query);
      if (response?.data?.code === 200) {
        setUpdate((old) => !old);
        dispatch(ShowAlert(response?.data?.message, "success"));
      } else {
        dispatch(ShowAlert("Something went wrong..!!", "error"));
      }
    } catch (error) {
      console.error(error);
      dispatch(ShowAlert("Something went wrong..!!", "error"));
    } finally {
      setTimeout(() => {
        setSuccessOpenModel(false);
        dispatch({
          type: LOADING_FALSE,
        });
      }, 1000);
    }
  };

  const setArchived = async (uuid, archive) => {
    try {
      const response = await ArchivedForms({
        uuid: uuid,
        is_archived: archive ? "0" : "1",
      });
      // console.log(response);
      if (response.data.success) {
        dispatch(ShowAlert(response?.data?.message, "success"));
        setUpdate((old) => old + 1);
      }
    } catch (error) {
      console.log(error);
      console.log(error?.response?.message);
    }
  };

  const handleDocStatus = (row) => {
    const data = row?.waitingFor;
    for (let i = 0; i < data?.length; i++) {
      if (data?.[i]?.signer_email === userData?.email) {
        return "Pending";
      }
    }
    if (data?.length > 0) {
      return "Awaiting Signature";
    }
    return "Completed";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handelNavigate = (row) => {
    console.log(
      "recruitment",
      userData?.type === "recruitment" && type === "template"
    );
    if (
      userData?.type === "recruitment" &&
      type !== "Draft" &&
      type !== "template"
    ) {
      navigate(`${routes.DOCUMENT_SIGN.DOC_SIGN}/${row?.uuid}`);
    } else if (userData?.type === "recruitment" && type === "Draft") {
      dispatch(
        setSigningMetadata({
          id: new Date().getTime(),
          sender: {
            name: `${userData?.first_name} ${userData?.last_name}`,
            email: userData?.email.toLowerCase(),
          },
          signers: [],
        })
      );
      navigate(`${routes.DOCUMENT_SIGN.DRAG_FIELDS}`, {
        state: {
          uuid:
            userData?.type === "recruitment" && type === "Draft"
              ? `${row?.uuid}`
              : "",
        },
      });
    } else if (userData?.type === "recruitment" && type === "template") {
      dispatch(
        setSigningMetadata({
          id: new Date().getTime(),
          sender: {
            name: `${userData?.first_name} ${userData?.last_name}`,
            email: userData?.email.toLowerCase(),
          },
          signers: [],
        })
      );
      navigate(`${routes.DOCUMENT_SIGN.SINNER}`, {
        state: {
          uuid:
            userData?.type === "recruitment" && type === "template"
              ? `${row?.uuid}`
              : "",
        },
      });
    } else {
      console.log("hellllllooooo");
      navigate(`${routes.CAN_DOCUMENT.DOC_SIGN_CAN}/${row?.uuid}`);
    }
  };
  const handelDelete = (row) => {
    if (type !== "template") {
      if(row?.is_password_required){
        setDeleteOpen(true);
        setIsDeleteClick(false);
      }else{
        setIsDeleteClick(true);
      }
      setDeleteId(row);
    } else {
      setOpenDeleteModal(true);
      setDeleteId(row);
    }
  };

  const deleteCall = async () => {
    try {
      dispatch({
        type: LOADING_TRUE,
      });

      let result = await deleteTemplate({ uuid: deleteId?.uuid });
      if (result?.data?.code === 200) {
        dispatch(ShowAlert(result?.data?.message, "success"));
        setUpdate((old) => !old);
        setOpenDeleteModal(false);
      } else {
        dispatch(ShowAlert(result?.data?.message, "error"));
      }
    } catch (error) {
      dispatch(ShowAlert("Something went wrong..!!", "error"));
      console.error(error);
    } finally {
      dispatch({
        type: LOADING_FALSE,
      });
    }
  };
  return (
    <>
    {!singleCandidate?.loading && <Box className={styles.candidateTable}>
      <Box className={styles.customBox}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{ "& td, & th": { border: 0 } }}
                className={styles.headingBox}
              >
                {titles.map((e, i) => (
                  <TableCell key={i} align={e === "Resend Form" ? "center" : ""}>
                    {e}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!rows?.length && (<TableRow>
                <TableCell colSpan={titles.length} className="text-center" style={{border: 'none'}}>No Data Found</TableCell>
              </TableRow>)}
              {rows?.length > 0 && rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "& td, & th": { border: 0 } }}
                  className={styles.contentBox}
                >
                  <TableCell>{row?.title}</TableCell>
                  {type !== "template" ? (
                    <TableCell>{row?.flow}</TableCell>
                  ) : (
                    <TableCell>{row?.category_details?.name}</TableCell>
                  )}
                  {userData?.type === "recruitment" &&
                    type !== "Draft" &&
                    type !== "template" && (
                      <TableCell>
                        {row?.created_at}
                      </TableCell>
                    )}
                  {userData?.type === "recruitment" &&
                    type !== "Draft" &&
                    type !== "template" && (
                      <TableCell>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#00CFC5",
                            color: "#FFFFFF",
                            border: "none",
                            cursor: "pointer",
                            minWidth: "100px",
                          }}
                        >
                          {handleDocStatus(row)}
                        </Button>
                      </TableCell>
                    )}

                  {userData?.type === "recruitment" &&
                  type !== "Draft" &&
                  type !== "template" ? (
                    <TableCell>
                      <Tooltip title="Active/Disabled" className="">
                        <ThemeProvider theme={theme}>
                          <Switch
                            classes={{
                              switchBase: theme.switchBase,
                              track: theme.track,
                            }}
                            checked={row?.status === 1 ? true : false}
                            size="small"
                            label={row?.status === 1 ? "Active" : "Disabled"}
                            onChange={(e) =>
                              handleStatus(row?.status === 1 ? 0 : 1, row?.uuid)
                            }
                          />
                        </ThemeProvider>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    type !== "Draft" &&
                    type !== "template" && (
                      <TableCell>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#00CFC5",
                            color: "#FFFFFF",
                            border: "none",
                            cursor: "pointer",
                            minWidth: "100px",
                          }}
                        >
                          {handleDocStatus(row)}
                        </Button>
                      </TableCell>
                    )
                  )}
                  {/* ) : (
                    <></>
                  )} */}

                  {!interview && (
                    <TableCell>
                      <Box className={styles.actionsBox}>
                        <Tooltip
                          title={
                            type !== "Draft" && type !== "template"
                              ? "Sign"
                              : "Edit"
                          }
                          className=""
                        >
                          <Edit
                            onClick={
                              () => handelNavigate(row)
                              // Fix the URL construction here
                            }
                          />
                        </Tooltip>
                        {userData?.type === "recruitment" &&
                          type !== "Draft" && (
                            <Tooltip title="Delete" className="">
                              <Dustin
                                onClick={() => {
                                  handelDelete(row);
                                }}
                              />
                            </Tooltip>
                          )}

                        {type !== "Draft" && type !== "template" && (
                          <Tooltip title="View" className="">
                            <Eye
                              onClick={() =>
                                navigate(
                                  `${
                                    userData?.type === "recruitment"
                                      ? routes.DOCUMENT_SIGN.VIEW_SIGNING_DOC
                                      : routes.CAN_DOCUMENT.VIEW_SIGNING_DOC_CAN
                                  }/${row?.uuid}`
                                )
                              }
                            />
                          </Tooltip>
                        )}
                        {type === "archive" || type === "All Forms" ? (
                          <Tooltip title="Archive" className="">
                            <Archive
                              onClick={() => {
                                setArchived(row?.uuid, row?.is_archived);
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {rows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="Box"
          count={total}
          rowsPerPage={15}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <DeleteEDoc
        setDeletePopup={setDeleteOpen}
        deletePopup={deleteOpen}
        row={deleteId}
        setUpdate={setUpdate}
        isDeleteClick={isDeleteClick}
        setIsDeleteClick={setIsDeleteClick}
      />
      {/* <DeleteForm
        openModal={deleteOpen}
        setOpenModal={setDeleteOpen}
        deleteId={deleteId}
        setDeletion={setUpdate}
        type={type}
      /> */}
      <EmailForm
        openModal={emailOpen}
        setOpenModal={setEmailOpen}
        data={data}
        setUpdate={setUpdate}
      />
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => setOpenDeleteModal(false)}
        open={openDeleteModal}
        showCloseIcon={true}
      >
        <h6>Are you sure want to delete?</h6>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              textTransform: "capitalize",
              marginTop: 0,
              fontFamily: "poppins-regular",
              background: "#00cfc5",
              color: "white",
              padding: "10px 7px",
              border: "none",
              width: "45%",
              borderRadius: "4px",
            }}
            onClick={deleteCall}
          >
            Delete
          </button>
          <button
            style={{
              textTransform: "capitalize",
              marginTop: 0,
              fontFamily: "poppins-regular",
              background: "white",
              color: "#00cfc5",
              padding: "10px 7px",
              width: "45%",
              borderRadius: "6px",
              border: "1px solid #00cfc5",
            }}
            onClick={() => setOpenDeleteModal(false)}
          >
            {" "}
            Cancel
          </button>
        </div>
      </Modal>
      </Box> }
      {singleCandidate?.loading && (
        <Box style={{position: 'relative', height: '10rem'}}>
          <Box className="loader" style={{top: '20%'}}>
            <CircularProgress />
          </Box>
        </Box>
      )}
   
    </>
  );
};

export default EDocTable;
