import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.scss";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { routes } from "../../../routes";
import { NavLink } from "react-router-dom";
import CustomSelect from "../../common/Select";
import Model from "../model/index";
import DeleteModel from "../delteModel/index";

const AllCandidates = () => {
  const orders = useSelector((state) => state?.rorders);
  const [show, setShow] = useState(false);
  const subscription = useSelector((state) => state?.pricing?.subscription);
  const total = orders?.singleOrder?.results?.OrderDetail?.reduce(
    (i, e) => i + Number(e?.price),
    0
  );

  return (
    <Box className={styles.parent}>
      <Box className={styles.header}>
        <Typography variant="h4">Cancel Subscription </Typography>

        {/* <Box className={styles.searchBox}>
          <BsSearch />
          <input type="text" placeholder="Search..." />
        </Box> */}
      </Box>
      
      <Box className={styles.candidateTable}>
      <Typography component={"h5"}>
          {subscription?.results?.month
            ? ""
            : "Not subscribed right now"}
        </Typography>
      {subscription?.results?.month && (
          <Grid container spacing={2}>
            <Grid item md={12} lg={8} sx={{ width: "100%" }}>
              <Box className={styles.boxTable}>
                <Box className={styles.rowHead}>
                  <Box className={styles.rowBody}>Name</Box>

                  <Box className={styles.rowBody}>price</Box>
                </Box>

                <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                  <Box className={styles.rowBody}>
                    {subscription?.results?.month} months Subscription
                  </Box>

                  <Box className={styles.rowBody}>
                    $ {subscription?.results?.price}
                  </Box>
                </Box>

                <Box className={`${styles.rowHead} ${styles.rowrow}`}>
                  <Box className={styles.rowBody}></Box>

                  <Box className={styles.rowBody}>
                    <Box
                      className={`button-primary ${styles.button}`}
                      style={{ margin: "0" }}
                    >
                      <Button onClick={() => setShow(true)}>
                        Unsubscribe{" "}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
      )}
      </Box>
      <Model open={show} setOpen={setShow}>
        <DeleteModel open={show} setOpen={setShow} />
      </Model>
    </Box>
  );
};

export default AllCandidates;
