import React, { useState } from "react";

/****
 * checkbox input component in pdf editing page.
 */
function ReceiverCheckbox({ field, signerIndex, fieldIndex, updateField, isFilledForm = false, pdfScale = 1 }) {
  const [isFilled, setFilled] = useState(true);

  const handleCheckChange = (e) => {
    setFilled(false);

    if (!e.target.checked) {
      setFilled(true);
    }
    let filedData = {
      isChecked: e.target.checked,
      isFilled: true,
    }
    field.isChecked = e.target.checked;
    field.isFilled = true;
    if (field.isChecked === false) {
      field.isFilled = false;
      filedData.isFilled = false;
    }
    updateField(signerIndex, fieldIndex, filedData);

  };

  return (
    <div
      style={{
        position: "absolute",
        left: field.positionFromParentLeft * pdfScale,
        top: field.positionFromParentTop * pdfScale,
        height: field.height * pdfScale,
        width: field.width * pdfScale,
        // border: "1px solid green",
      }}>
      <input
        onChange={(e)=>{
          if(!isFilledForm){
            handleCheckChange(e);
          }
        }}
        disabled={isFilledForm}
        placeholder={field.placeholder}
        style={{
          width: "100%",
          height: "100%",
          fontSize: field.fontSize * pdfScale,
          border: `1px solid ${field.errorMessage && isFilled ? "red" : "#767676"}`,
        }}
        type='checkbox'
      />
    </div>
  );
}

export default ReceiverCheckbox;
